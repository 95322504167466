import React, { useEffect, useState } from "react";
import { FaTrash, FaEdit } from "react-icons/fa";
import { addEmployee, updateEmployee } from "../../services/employeeApi";
import { ROLES, employeeFields } from "../../constants/constant";
import InputField from "../../UI/InputField";
import MyButton from "../../UI/MyButton";
import {
  appendIfDefined,
  createImagePreview,
  getErrorMessage,
  validateFields,
} from "../../utils/functions";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../services/userApi";
import { login, updateUser } from "../../redux/authSlice";
import Loader from "../../UI/Loader";
import imageConstant from "../../constants/imageConstant";

const Profile = ({}) => {
  const { user } = useSelector((state) => state.auth);

  const [profileData, setProfileData] = useState({});
  const [isProfileEdit, setIsProfileEdit] = useState(false);
  const [images, setImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      setLoading(false);
      return;
    }

    (async () => {
      try {
        const user = await getUser();
        if (user) {
          dispatch(
            updateUser({
              user,
            })
          );
        }
        setProfileData(user);
      } catch (err) {
        toast.error(getErrorMessage(err));
      } finally {
        setLoading(false);
      }
    })();
  }, [dispatch]);

  if (loading) {
    return <Loader />;
  }

  const handleInputChange = (field, value) => {
    setProfileData((prevItem) => ({
      ...prevItem,
      [field]: value,
    }));
  };

  const handleSelectChange = (field, selectedValue) => {
    setProfileData((prevItem) => ({
      ...prevItem,
      [field]: selectedValue.value,
    }));
  };

  const handleImageUpload = (e) => {
    const newImages = [e.target.files[0]];
    setImages(newImages);

    const previewArray = newImages.map((image) => {
      return createImagePreview(image);
    });
    setImagePreviews(previewArray);
  };

  const handleAddEmployee = async (e) => {
    try {
      e.preventDefault();

      const missingField = validateFields(
        ["name", "age", "email", "role", "mobileNumber"],
        profileData
      );
      if (missingField) {
        return toast.error(missingField);
      }

      //   if (profileData.password !== profileData.confirmPassword) {
      //     return toast.error("Password not matching!");
      //   }

      // if (profileData.age < 18) {
      //   return toast.error("Employee should be atleast 18");
      // }

      setLoading(true);

      const formData = new FormData();
      appendIfDefined(formData, "name", profileData.name);
      appendIfDefined(formData, "age", profileData.age);
      // appendIfDefined(formData, "department", profileData.department);
      // appendIfDefined(formData, "password", profileData.password);
      // appendIfDefined(formData, "email", profileData.email);
      // appendIfDefined(formData, "provider", ROLES.RESTAURANT_ADMIN);
      // appendIfDefined(formData, "role", profileData.role);
      appendIfDefined(formData, "mobileNumber", profileData.mobileNumber);

      images.forEach((image, index) => {
        formData.append(`image`, image);
      });

      const user = await updateEmployee(formData, `${profileData._id}`);

      dispatch(
        updateUser({
          user: {
            ...user,
            restaurant: user?.restaurant,
          },
        })
      );
      toast.success("Profile updated successfully!");
      setProfileData(user);
      setLoading(false);
      setImages([]);
      setImagePreviews([]);
    } catch (error) {
      if (Array.isArray(error)) {
        error.forEach((err) => {
          toast.error(getErrorMessage(err));
        });
      } else {
        toast.error(getErrorMessage(error));
      }
      setLoading(false);
    }
  };
  const disabledFields = [
    "role",
    "password",
    "confirmPassword",
    "email",
    "department",
  ];
  const removeFields = ["password", "confirmPassword"];

  return (
    <div className="lg:ml-7 rounded-2xl p-3">
      <form
        className="lg:grid lg:grid-cols-2 lg:gap-8 gap-3 flex flex-col"
        onSubmit={handleAddEmployee}
      >
        <div className="col-span-2 flex justify-center items-center gap-4 mb-4">
          <label htmlFor="profile_pic" className="relative cursor-pointer">
            <img
              src={
                imagePreviews?.[0] || profileData.avatar || imageConstant.waiter
              }
              alt="Profile"
              className="aspect-square w-32 rounded-full ring-2 ring-gray-800"
            />
            <FaEdit className="absolute bottom-1 right-1 text-white bg-gray-500 rounded-full p-1 text-xl" />
          </label>
          <input
            id="profile_pic"
            hidden
            type="file"
            onChange={handleImageUpload}
            accept="image/png, image/jpeg"
          />
        </div>

        {employeeFields.map((field, index) => (
          <div key={index} className="col-span-1">
            {removeFields.includes(field.name) ? null : (
              <InputField
                labelName={field.label}
                inputClass="h-10 bg-[#F6F1EE] rounded-lg border border-black border-opacity-20"
                isRequired={field.isRequired}
                type={field.type}
                name={field.name}
                value={profileData[field.name]}
                options={field.options}
                labelClass="text-black mb-1 font-semibold"
                disabled={disabledFields.includes(field.name)}
                onChange={
                  field.type === "select"
                    ? (selectedValue) =>
                        handleSelectChange(field.name, selectedValue)
                    : (e) => handleInputChange(field.name, e.target.value)
                }
              />
            )}
          </div>
        ))}

        <div className="col-span-2 flex justify-center">
          <MyButton
            className="bg-primary text-white rounded-xl p-2 w-full lg:w-28"
            type="submit"
            bypassRoleCheck
            loading={loading}
          >
            {"Update"}
          </MyButton>
        </div>
      </form>
    </div>
  );
};

export default Profile;
