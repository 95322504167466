import {
  IconButton,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter } from "../../../utils/functions";

const RestaurantTable = ({
  restaurants,
  showConfirmationModal,
  setShowRestaurant,
}) => {
  const [showAllDetails, setShowAllDetails] = useState(false);
  const navigate = useNavigate();

  const handleShowDetails = () => {
    setShowAllDetails((prev) => !prev);
  };

  const excludedKeys = [
    "__v",
    "createdAt",
    "updatedAt",
    "_id",
    "restaurant",
    "description",
    "price",
    "password",
    "provider",
    "owner",
    "address",
  ];

  const restaurantFields = Object.keys(restaurants[0] || {}).filter(
    (key) => !excludedKeys.includes(key)
  );

  const handleEdit = (restaurant) => {
    navigate(`/restaurants`, {
      state: { restaurant, isEdit: true },
      replace: true,
    });
    setShowRestaurant(true);
  };

  return (
    <TableContainer overflowX="auto">
      <Table variant="striped" colorScheme="gray">
        <Thead>
          <Tr>
            {restaurantFields.map((field) => {
              if (field === "avatar")
                return <Th key={field}>{capitalizeFirstLetter("Photo")}</Th>;
              return <Th key={field}>{capitalizeFirstLetter(field)}</Th>;
            })}
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {restaurants.map((restaurant, index) => (
            <Tr key={index}>
              {restaurantFields.map((field) => (
                <Td key={field}>
                  {field === "avatar" ? (
                    <Image
                      src={restaurant[field]}
                      alt="Restaurant Photo"
                      boxSize="50px"
                      objectFit="cover"
                      borderRadius="md"
                    />
                  ) : showAllDetails ? (
                    <Text>{restaurant[field]}</Text>
                  ) : (
                    <Text isTruncated>{restaurant[field]}</Text>
                  )}
                </Td>
              ))}
              <Td>
                <IconButton
                  colorScheme="blue"
                  variant="outline"
                  size="sm"
                  aria-label="Edit"
                  icon={<FaEdit />}
                  className="m-2"
                  onClick={() => handleEdit(restaurant)}
                />
                <IconButton
                  colorScheme="red"
                  variant="outline"
                  size="sm"
                  aria-label="Delete"
                  icon={<FaTrash />}
                  onClick={() => showConfirmationModal(restaurant)}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default RestaurantTable;
