import React, { useState } from "react";
import { FaEdit, FaRegEye, FaTrash } from "react-icons/fa";
import {
  capitalizeFirstLetter,
  getErrorMessage,
  splitFieldName,
} from "../../../utils/functions";
import { useNavigate } from "react-router-dom";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Image,
  Text,
  TableContainer,
  Switch,
  Box,
  Flex,
} from "@chakra-ui/react";
import InputField from "../../../UI/InputField";
import { Label } from "mdi-material-ui";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { updateEmployee } from "../../../services/employeeApi";
import ViewEmployeeModal from "./ViewEmployeeModal";

const EmployeeCard = ({
  employee,
  showConfirmationModal,
  handleEdit,
  handleSetEmployeeDetail,
  update,
}) => {
  return (
    <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p="4" m="2">
      <Flex className="justify-between items-start">
        <Flex direction="column" mb="4">
          <div>
            <div>
              <b className="mr-1">Name:</b>
              {employee.name}
            </div>
            <div>
              <b className="mr-1">Role:</b>
              {splitFieldName(employee.role)}
            </div>
            <div>
              <b className="mr-1">Department:</b>
              {employee.department}
            </div>
          </div>
        </Flex>

        <Image
          src={employee.avatar}
          alt={`${employee.name}'s avatar`}
          boxSize="50px" // Set the size according to your requirement
        />
        {/* Employee Photo on the right */}
      </Flex>

      {/* Buttons at the bottom */}
      <Flex justify="space-between">
        <Flex align="center">
          <Switch
            isChecked={employee.isPresent}
            onChange={(e) => {
              update(e, employee, {
                isPresent: !employee.isPresent,
              });
            }}
            colorScheme="teal"
            size="md"
          />
          <Text ml="2">{employee.isPresent ? "Present" : "Absent"}</Text>
        </Flex>
        <Flex>
          <IconButton
            colorScheme="green"
            variant="outline"
            size="sm"
            aria-label="View"
            icon={<FaRegEye />}
            onClick={() => handleSetEmployeeDetail(employee)}
          />
          <IconButton
            colorScheme="blue"
            variant="outline"
            size="sm"
            aria-label="Edit"
            icon={<FaEdit />}
            ml="2"
            onClick={() => handleEdit(employee)}
          />
          <IconButton
            colorScheme="red"
            variant="outline"
            size="sm"
            aria-label="Delete"
            icon={<FaTrash />}
            ml="2"
            onClick={() => showConfirmationModal(employee)}
          />
        </Flex>
      </Flex>
    </Box>
  );
};

const EmployeeTable = ({
  employees,
  showConfirmationModal,
  setShowEmployee,
  setEmployees,
}) => {
  const mobileView = useSelector((state) => state.auth.mobileView);
  const [showAllDetails, setShowAllDetails] = useState(false);
  const [employeeDetail, setEmployeeDetail] = useState({});
  const [showEmployeeDetailModal, setShowEmployeeDetailModal] = useState(false);

  const navigate = useNavigate();

  const handleShowDetails = () => {
    setShowAllDetails((prev) => !prev);
  };

  const { user } = useSelector((state) => state.auth);

  const excludedKeys = [
    "__v",
    "createdAt",
    "updatedAt",
    "_id",
    "restaurant",
    "description",
    "price",
    "password",
    "provider",
    "isPresent",
    // "avatar",
    "age",
  ];

  const employeeFields = Object.keys(employees[0] || {}).filter(
    (key) => !excludedKeys.includes(key)
  );

  const handleSetEmployeeDetail = (employee) => {
    setEmployeeDetail(employee);
    setShowEmployeeDetailModal(true);
  };

  const handleEdit = (employee) => {
    if (employee._id === user._id)
      return toast.error(
        "You can't edit from here. Go to your profile and update your details."
      );
    navigate(`/employees`, {
      state: { employee, isEdit: true, showEmployee: true },
      replace: true,
    });
    setShowEmployee(true);
  };

  const update = async (e, employee, data) => {
    e.preventDefault();
    try {
      await updateEmployee(data, `${employee._id}`);
      setEmployees((prevEmployees) =>
        prevEmployees.map((emp) => {
          if (emp._id === employee._id) {
            return { ...emp, ...data };
          }
          return emp;
        })
      );
    } catch (error) {
      if (Array.isArray(error)) {
        // If error is an array, toast each error message
        error.forEach((err) => {
          toast.error(getErrorMessage(err));
        });
      } else {
        // If not an array, toast the single error message
        toast.error(getErrorMessage(error));
      }
    }
  };

  return (
    <Box>
      {mobileView ? (
        employees.map((employee, index) => (
          <EmployeeCard
            key={index}
            employee={employee}
            showConfirmationModal={showConfirmationModal}
            handleEdit={handleEdit}
            update={update}
            handleSetEmployeeDetail={handleSetEmployeeDetail}
          />
        ))
      ) : (
        <TableContainer overflowX="auto">
          <ViewEmployeeModal
            details={employeeDetail}
            isOpen={showEmployeeDetailModal}
            onClose={() => setShowEmployeeDetailModal(false)}
          />
          <Table variant="striped" colorScheme="gray">
            <Thead>
              <Tr>
                <Th>Email</Th>
                <Th>Name</Th>
                <Th>Phone Number</Th>
                <Th>Role</Th>
                <Th>Department</Th>
                <Th>Attendance</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {employees.map((employee, index) => {
                return (
                  <Tr key={index}>
                    <Td>{employee.email}</Td>
                    <Td>{employee.name}</Td>
                    <Td>{employee.mobileNumber}</Td>
                    <Td>{splitFieldName(employee.role)}</Td>
                    <Td>{employee.department}</Td>
                    <Td>
                      <div className="flex justify-center items-center">
                        <Switch
                          isChecked={employee.isPresent}
                          onChange={(e) => {
                            update(e, employee, {
                              isPresent: !employee.isPresent,
                            });
                          }}
                          colorScheme="teal"
                          size="md"
                        />
                        <p className="ml-2">
                          {employee.isPresent ? "Present" : "Absent"}
                        </p>
                      </div>
                    </Td>
                    <Td>
                      <IconButton
                        colorScheme="green"
                        variant="outline"
                        size="sm"
                        aria-label="Edit"
                        icon={<FaRegEye />}
                        className=""
                        onClick={() => handleSetEmployeeDetail(employee)}
                      />
                      <IconButton
                        colorScheme="blue"
                        variant="outline"
                        size="sm"
                        aria-label="Edit"
                        icon={<FaEdit />}
                        className="m-2"
                        onClick={() => handleEdit(employee)}
                      />
                      <IconButton
                        colorScheme="red"
                        variant="outline"
                        size="sm"
                        aria-label="Delete"
                        icon={<FaTrash />}
                        onClick={() => showConfirmationModal(employee)}
                      />
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )}
      <ViewEmployeeModal
        details={employeeDetail}
        isOpen={showEmployeeDetailModal}
        onClose={() => setShowEmployeeDetailModal(false)}
      />
    </Box>
  );
};

export default EmployeeTable;
