import { ChakraProvider } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { Pages, ROLES } from "./constants/constant";

import NotFound from "./Pages/NotFound";
import NavBar from "./UI/NavBar";
import Sidebar from "./UI/Sidebar";
import { handleResolution, login, logout } from "./redux/authSlice";
import { getUser, logout as logoutAPI } from "./services/userApi";
import Loader from "./UI/Loader";
import Layout from "./UI/Layout/Layout";
import {
  setCategories,
  setQuantityUnits,
  setVariants,
} from "./redux/settingsSlice";
import {
  getAllCategories,
  getAllQuantityUnits,
  getAllVariants,
} from "./services/settingsApi";
import toast from "react-hot-toast";
import { getErrorMessage } from "./utils/functions";

const PrivateRoute = ({ page, ...props }) => {
  const { auth } = useSelector((state) => state);
  const { isAuth, user } = auth;

  if (isAuth && !page?.roles?.includes(user?.role)) {
    return <Navigate to="/not-found" />;
  }

  if (
    (user?.role === ROLES.RESTAURANT_ADMIN ||
      user?.role === ROLES.SUPER_ADMIN) &&
    user?.restaurant?.isVerified &&
    user?.restaurant?.isSubscriptionActive &&
    page.path === "/verification"
  ) {
    return <Navigate to="/dashboard" />;
  }

  if (
    page.path !== "/verification" &&
    page.path !== "/subscription" &&
    page.path !== "/profile" &&
    ((user && !user?.restaurant) ||
      ((!user?.restaurant?.isVerified ||
        !user?.restaurant?.isSubscriptionActive) &&
        !user?.restaurant?.isFreeTrial &&
        (user?.role === ROLES.EMPLOYEE ||
          user?.role === ROLES.RESTAURANT_ADMIN)))
  ) {
    return <Navigate to="/verification" />;
  }

  if (!isAuth) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="main_comp">
      <Sidebar />
      <div className="main">
        <NavBar />
        <div style={{ height: "90vh", marginTop: "0vh" }}>
          <Layout>
            <page.component />
          </Layout>
        </div>
      </div>
    </div>
  );
};

const AuthenticationRoute = ({ page, ...props }) => {
  const { auth } = useSelector((state) => state);
  const { isAuth, user } = auth;

  if (isAuth) {
    if (user?.role === ROLES.RESTAURANT_ADMIN) {
      return <Navigate to="/menus" />;
    }

    return <Navigate to="/orders" />;
  }

  return <page.component />;
};
const App = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const { auth } = useSelector((state) => state);
  const { isAuth, user } = auth;

  const handleLogout = async () => {
    try {
      await logoutAPI();
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      dispatch(logout());
    }
  };

  if (user?.role === ROLES.EMPLOYEE && !user?.isPresent) {
    handleLogout();
  }

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      setLoading(false);
      return;
    }

    // Fetch user on component render to get the recent user details
    (async () => {
      try {
        const res = await getUser();
        if (res) {
          dispatch(
            login({
              token,
              user: res,
              isAuth: true,
            })
          );
        }
      } catch (err) {
        toast.error(getErrorMessage(err));
      } finally {
        setLoading(false);
      }
    })();
  }, [dispatch]);

  const resize = () => {
    dispatch(handleResolution(window.innerWidth <= 800));
  };

  useEffect(() => {
    resize();
    window.addEventListener("resize", resize);
  }, []);

  const fetchVariants = async () => {
    const res = await getAllVariants();
    dispatch(setVariants(res));
  };

  const fetchCategories = async () => {
    const res = await getAllCategories();
    dispatch(setCategories(res));
  };

  const fetchQuantityUnits = async () => {
    const res = await getAllQuantityUnits();
    dispatch(setQuantityUnits(res));
  };

  useEffect(() => {
    try {
      if (isAuth && user.restaurant) {
        Promise.all([fetchCategories(), fetchQuantityUnits(), fetchVariants()]);
      }
    } catch (error) {
      toast.error(getErrorMessage(error));
    }
  }, [isAuth]);

  if (loading) {
    return <Loader />;
  }

  return (
    <ChakraProvider>
      <Routes>
        {Pages.map((page, index) => {
          return page.isAuth ? (
            <Route
              key={index}
              path={page.path}
              element={<PrivateRoute page={page} />}
            />
          ) : page.isAutheticationRoute ? (
            // <Route key={index} element={<page.component />} path={page.path} />
            <Route
              key={index}
              path={page.path}
              element={<AuthenticationRoute page={page} />}
            />
          ) : (
            <Route key={index} path={page.path} element={<page.component />} />
          );
        })}
        <Route path="/not-found" element={<NotFound />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </ChakraProvider>
  );
};

export default App;
