import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { getRestaurantAnalytics } from "../../services/restaurantApi";
import ReactApexChart from "react-apexcharts";
import { BsGraphUp } from "react-icons/bs";
import { MdMoney } from "react-icons/md";
import { TfiMoney } from "react-icons/tfi";
import Loader from "../../UI/Loader";
import OldOrders from "../Orders/OldOrders";

const months = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};

const week = {
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
  7: "Sunday",
};

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [analytics, setAnalytics] = useState({});

  useEffect(() => {
    setLoading(true);
    getRestaurantAnalytics()
      .then((res) => {
        setAnalytics(res);
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  }, []);

  const monthlyEarningsEachMonthData = {
    series: [
      {
        name: "Months",
        data:
          analytics?.monthlyEarningsEachMonth?.map((item) => item.amount) || [],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        title: "Month",
        categories: Object.keys(months).map((key) => months[key]),
      },
      yaxis: {
        title: {
          text: "Amount",
        },
      },
    },
  };

  const dailyEarningsEachMonthData = {
    series: [
      {
        name: "Day",
        data: analytics?.dailyEarning?.map((item) => item.amount) || [],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "bar",
        zoom: {
          enabled: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: true,
        // format: "dd MMM yyyy",
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        title: "Day",
        categories: analytics?.dailyEarning?.map((item) => item.day) || [],
      },
      yaxis: {
        title: {
          text: "Amount",
        },
      },
    },
  };

  if (loading) return <Loader />;

  return (
    <div className="p-4">
      <div className="flex justify-center items-center gap-6 mt-7 lg:flex-1 w-full lg:flex-row flex-col">
        <div className=" p-4 rounded-xl flex-1 w-full bg-green-100 border border-green-400">
          <h3 className="font-medium text-left text-md">Sales</h3>
          <div className="flex justify-between items-center gap-3 mt-5">
            <BsGraphUp size={30} className="text-green-500 mt-1 font-bold" />
            <p className=" text-4xl">{analytics.countOfOrders || 0}</p>
          </div>
        </div>
        <div className=" p-4 rounded-xl flex-1 w-full bg-sky-100 border border-sky-400">
          <h3 className="font-medium text-left text-md">Total Earning</h3>
          <div className="flex justify-between items-center gap-3 mt-5">
            <MdMoney size={40} className="text-blue-500 mt-1" />
            <p className=" text-4xl">₹{analytics.totalAmount || 0}</p>
          </div>
        </div>
        <div className=" p-4 rounded-xl flex-1 w-full bg-orange-100 border border-orange-400">
          <h3 className="font-medium text-left text-md">Monthly Earning</h3>
          <div className="flex justify-between items-center gap-3 mt-5">
            <TfiMoney size={30} className="text-orange-500 mt-1" />
            <p className=" text-4xl">₹{analytics?.monthlyEarning || 0}</p>
          </div>
        </div>
      </div>
      <div
        id="chart"
        className="flex justify-center items-stretch gap-6 mt-10 lg:flex-row flex-col flex-1"
      >
        <div className="bg-white p-4 rounded-xl flex flex-col flex-1 border border-gray-300">
          <h3>Monthly Overview</h3>
          <ReactApexChart
            options={monthlyEarningsEachMonthData?.options}
            series={monthlyEarningsEachMonthData?.series}
            type="line"
          />
        </div>
        <div className="bg-white p-4 rounded-xl flex flex-col flex-1 border border-gray-300">
          <h3>Weekly Overview</h3>
          <ReactApexChart
            options={dailyEarningsEachMonthData?.options}
            series={dailyEarningsEachMonthData?.series}
            type="bar"
          />
        </div>
        <div id="html-dist"></div>
      </div>
      <OldOrders />
    </div>
  );
};

export default Dashboard;
