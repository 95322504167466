import {
  Button,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import InputField from "../../UI/InputField";
import { ROLES } from "../../constants/constant";
import { getErrorMessage, splitFieldName } from "../../utils/functions";
import { getAllMenu } from "../../services/menuApi";
import toast from "react-hot-toast";
import Loader from "../../UI/Loader";
import {
  createOrder,
  getAllOrders,
  removeItemFromOrder,
  markAsPaid,
  updateOrder,
  markItemAsDelivered,
  markItemAsPrepared,
} from "../../services/orderApi";
import MyButton from "../../UI/MyButton";

const statusObj = {
  reordered: { color: "#2196F3" }, // Blue
  completed: { color: "#FF5252" }, // Red
  ongoing: { color: "#4CAF50" }, // Green
  cooking: { color: "#FFC107" }, // Yellow
  preparing: { color: "#009688" }, // Teal
  withWaiter: { color: "#2ACABC" },
  inKitchen: { color: "#2ddeB3" },
  pending: { color: "#2ddeB3" },
};

const Orders = () => {
  const mobileView = useSelector((state) => state.auth.mobileView);
  const { auth } = useSelector((state) => state);
  const { isAuth, user } = auth;
  const restaurant = user.restaurant._id;
  const [isOpen, setIsOpen] = useState(false);
  const [isAddItemModalOpen, setIsAddItemModalOpen] = useState(false);
  const [availableItems, setAvailableItems] = useState([]);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedTable, setSelectedTable] = useState(null);
  const [isModalOpenForTable, setIsModalOpenForTable] = useState(null);
  const [orderCompletionConfirmation, setOrderCompletionConfirmation] =
    useState(false);

  useEffect(() => {
    getAllMenu()
      .then((res) => setAvailableItems(res))
      .catch((err) => toast.error(getErrorMessage(err)))
      .finally(setLoading(false));
  }, []);

  const fetchOrders = async () => {
    if (isAuth) {
      let query = `?isPaid=false`;
      const res = await getAllOrders(query);
      setOrders(res);
    }
  };

  useEffect(() => {
    const fetchOrdersWithPolling = async () => {
      await fetchOrders();
      setTimeout(fetchOrdersWithPolling, 3000); // Poll every 5 seconds (adjust as needed)
    };

    fetchOrdersWithPolling();

    return () => clearTimeout(fetchOrdersWithPolling);
  }, []);

  const toggleDialog = (table) => {
    setIsModalOpenForTable(isModalOpenForTable === table ? null : table);
    setSelectedTable(table);
  };

  const toggleAddItemModal = () => {
    setIsAddItemModalOpen(!isAddItemModalOpen);
  };

  const [menuItems, setMenuItems] = useState([]);

  function calculateTotalPrice() {
    let totalPrice = 0;
    menuItems?.forEach(
      (item) =>
        (totalPrice += item.price * (item.qty || item.qty === 0 ? item.qty : 1))
    );
    return totalPrice;
  }

  const addItem = async (item, qtyOption) => {
    try {
      await createOrder({
        table: selectedTable,
        cartItems: [
          {
            item: {
              ...item,
              quantityUnit: qtyOption.quantityUnit,
              quantityQuantity: qtyOption.quantity,
              itemPrice: qtyOption.price,
              quantityOptionId: qtyOption._id,
            },
            // quantityOption: qtyOption,
            quantityUnit: qtyOption.quantityUnit,
            quantityQuantity: qtyOption.quantity,
            itemPrice: qtyOption.price,
            quantityOptionId: qtyOption._id,
          },
        ],
        quantityOptionId: qtyOption._id,
        restaurant,
        item,
        customer: { id: Math.random() },
      });
      setIsAddItemModalOpen(false);
      toast.success("Item added successfully!");
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      await fetchOrders();
    }
  };

  const markAsPaidForCash = async (restaurantOrderId) => {
    try {
      await markAsPaid({
        restaurantOrderId,
      });
      toggleDialog();
      await fetchOrders();
      toast.success("Item added successfully!");
    } catch (error) {
      toast.error(getErrorMessage(error));
    }
  };

  const removeFromCart = async (item) => {
    try {
      await removeItemFromOrder({
        table: selectedTable,
        itemId: item?.item?._id,
        restaurant,
      });
      setIsAddItemModalOpen(false);
      toast.success("Item removed successfully!");
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      await fetchOrders();
    }
  };

  const handleStatusChange = async (e, orderId, isModal = false) => {
    try {
      let newStatus = e?.target?.value || "completed";

      if (newStatus === "completed" && !isModal) {
        setOrderCompletionConfirmation(true);
        return;
      }

      await updateOrder(
        {
          status: newStatus,
        },
        `?id=${orderId}`
      );

      const updatedOrders = orders.map((order) => {
        if (order._id === orderId) {
          return { ...order, status: newStatus };
        }
        return order;
      });

      setOrders(updatedOrders);
      if (isModal) {
        setOrderCompletionConfirmation(false);
      }
    } catch (error) {
      toast.error(getErrorMessage(error));
    }
  };

  const handleMarkAsDelivered = async (orderId, itemId, quantityOptionId) => {
    try {
      await markItemAsDelivered({
        orderId,
        itemId,
        quantityOptionId,
      });
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      await fetchOrders();
    }
  };

  const handleMarkAsPrepared = async (orderId, itemId, quantityOptionId) => {
    try {
      await markItemAsPrepared({
        orderId,
        itemId,
        quantityOptionId,
      });
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      await fetchOrders();
    }
  };

  if (loading) {
    return <Loader />;
  }
  console.log(user.department);
  return (
    <div className="p-3">
      {mobileView ? (
        orders?.map((order, index) => (
          <div key={order._id} className="mb-4 p-4 border rounded">
            <div>
              <b>Order No:</b> {index + 1}
            </div>
            <div>
              <b>Table:</b> {order.table}
            </div>
            {user.role !== ROLES.EMPLOYEE && (
              <div>
                <b>Assigned Waiter:</b>
                <p className="capitalize">{order.waiter.name}</p>
              </div>
            )}
            <div className="flex justify-between items center w-full mt-3">
              <Select
                value={order.status}
                onChange={(e) => handleStatusChange(e, order._id)}
                borderRadius="full"
                variant="solid"
                bg={statusObj[order.status]?.color}
                color="white"
                size={"sm"}
                width="50%"
              >
                {Object.keys(statusObj).map((status) => (
                  <option key={status} value={status} className="text-black">
                    {splitFieldName(status)}
                    {/* {status} */}
                  </option>
                ))}
              </Select>
              <div>
                <Button
                  colorScheme="blue"
                  variant="outline"
                  size={"sm"}
                  onClick={() => toggleDialog(order.table)}
                >
                  View order
                </Button>
              </div>
              <Modal
                isCentered
                isOpen={isModalOpenForTable === order.table}
                onClose={() => {
                  toggleDialog(order.table);
                  setSelectedTable(null);
                }}
                size={"4xl"}
              >
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Order Details</ModalHeader>
                  <ModalCloseButton />
                  <Table size={mobileView ? "sm" : "md"} className="p-2">
                    <Thead>
                      <Tr>
                        <Th>Item Name</Th>
                        <Th>Qty</Th>
                        <Th>Price</Th>
                        {user.department !== "Chef" && <Th>Delivered</Th>}
                        {user.department !== "Waiter" && <Th>Prepared</Th>}
                        {user.department !== "Chef" && <Th>Actions</Th>}
                      </Tr>
                    </Thead>
                    <Tbody>
                      {order?.cartItems?.map((item) =>
                        user.department === "Chef" &&
                        user.category === item.item.category ? (
                          <Tr key={item.item.name}>
                            <Td>{item.item.name}</Td>
                            <Td>{item.quantity}</Td>
                            <Td>Rs. {item.itemPrice}</Td>
                            {user.department !== "Waiter" && (
                              <Td>
                                <InputField
                                  type={"checkbox"}
                                  inputClass={"text-green-500"}
                                  value={item.isPrepared}
                                  onChange={() =>
                                    handleMarkAsPrepared(
                                      order?._id,
                                      item?.item?._id,
                                      item?.quantityOptionId
                                    )
                                  }
                                />
                              </Td>
                            )}
                            {user.department !== "Chef" && (
                              <>
                                <Td>
                                  <InputField
                                    type={"checkbox"}
                                    inputClass={"text-green-500"}
                                    value={item.isDelivered}
                                    onChange={() =>
                                      handleMarkAsDelivered(
                                        order?._id,
                                        item?.item?._id,
                                        item?.quantityOptionId
                                      )
                                    }
                                  />
                                </Td>
                              </>
                            )}
                            {user.department !== "Chef" && (
                              <Td>
                                <MyButton
                                  variant="link"
                                  colorScheme="blue"
                                  size="sm"
                                  onClick={() => removeFromCart(item)}
                                  bypassRoleCheck
                                >
                                  Remove
                                </MyButton>
                              </Td>
                            )}
                          </Tr>
                        ) : (
                          user.department !== "Chef" && (
                            <Tr key={item.item.name}>
                              <Td>{`${item.item.name} (${item.quantityQuantity} ${item.quantityUnit})`}</Td>
                              <Td>{item.quantity}</Td>
                              <Td>Rs. {item.itemPrice}</Td>
                              {user.department !== "Chef" && (
                                <Td>
                                  <InputField
                                    type={"checkbox"}
                                    inputClass={"text-green-500"}
                                    value={item.isDelivered}
                                    onChange={() =>
                                      handleMarkAsDelivered(
                                        order?._id,
                                        item?.item?._id,
                                        item?.quantityOptionId
                                      )
                                    }
                                  />
                                </Td>
                              )}
                              {user.department !== "Waiter" && (
                                <Td>
                                  <InputField
                                    type={"checkbox"}
                                    inputClass={"text-green-500"}
                                    value={item.isPrepared}
                                    onChange={() =>
                                      handleMarkAsPrepared(
                                        order?._id,
                                        item?.item?._id,
                                        item?.quantityOptionId
                                      )
                                    }
                                  />
                                </Td>
                              )}
                              <Td>
                                <MyButton
                                  variant="link"
                                  colorScheme="blue"
                                  size="sm"
                                  onClick={() => removeFromCart(item)}
                                  bypassRoleCheck
                                >
                                  Remove
                                </MyButton>
                              </Td>
                            </Tr>
                          )
                        )
                      )}
                      {order?.instructions && (
                        <Tr key={"total"}>
                          <Td colSpan={2}>
                            <b>Instructions : </b>
                          </Td>
                          <Td colSpan={2}>{order?.instructions}</Td>
                        </Tr>
                      )}
                      <Tr key={"total"}>
                        <Td colSpan={2}>
                          <b>Total Price :</b>
                        </Td>
                        <Td colSpan={2}>
                          Rs. {order?.bill?.totalAmount} + Rs.
                          {order?.waiterTip || 0} (tip) =
                          {order?.bill?.totalAmount + (order?.waiterTip || 0)}{" "}
                        </Td>
                      </Tr>
                    </Tbody>

                    {user.department !== "Chef" && (
                      <div className="flex m-1 ">
                        <Button
                          variant={"outline"}
                          bgColor={"orange.300"}
                          className="m-1"
                          onClick={() => {
                            setIsAddItemModalOpen(true);
                            setSelectedTable(order.table);
                          }}
                        >
                          Add Item
                        </Button>

                        {order.isCashPaymentRequested && (
                          <Button
                            variant={"outline"}
                            bgColor={"orange.300"}
                            className="m-1"
                            onClick={() => markAsPaidForCash(order._id)}
                          >
                            Mark as Paid
                          </Button>
                        )}
                      </div>
                    )}
                  </Table>
                </ModalContent>
              </Modal>
            </div>
          </div>
        ))
      ) : (
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>Order No</Th>
                <Th>Table</Th>
                {user.role !== ROLES.EMPLOYEE && <Th>Assigned Waiter</Th>}
                <Th>Status</Th>
                {user.department !== "Chef" && <Th>Action</Th>}
              </Tr>
            </Thead>
            <Tbody>
              {orders?.map((row, index) => (
                <Tr key={row._id}>
                  <Td>{index + 1}</Td>
                  <Td>{row.table}</Td>
                  {user.role !== ROLES.EMPLOYEE && (
                    <Td className="capitalize">{row?.waiter?.name}</Td>
                  )}
                  <Td>
                    <Select
                      value={row.status}
                      onChange={(e) => handleStatusChange(e, row._id)}
                      borderRadius="full"
                      variant="solid"
                      bg={statusObj[row?.status]?.color}
                      color="white"
                      size={"sm"}
                    >
                      {Object.keys(statusObj).map((status) => (
                        <option
                          key={status}
                          value={status}
                          className="text-black"
                        >
                          {splitFieldName(status)}
                        </option>
                      ))}
                    </Select>
                    <Modal
                      isCentered
                      isOpen={orderCompletionConfirmation}
                      onClose={() => {
                        setOrderCompletionConfirmation(false);
                      }}
                      size={"4xl"}
                    >
                      <ModalOverlay />
                      <ModalContent>
                        <ModalHeader>Complete Order</ModalHeader>

                        <p className="ml-4 p-2 font-2xl">
                          Are you sure want to mark the order as complete?
                        </p>
                        <div className="p-2 w-30 flex justify-center items-center">
                          <Button
                            colorScheme="green"
                            onClick={() =>
                              handleStatusChange(null, row._id, true)
                            }
                          >
                            Confirm
                          </Button>
                        </div>
                        <ModalCloseButton />
                      </ModalContent>
                    </Modal>
                  </Td>
                  <Td>
                    <Button
                      colorScheme="blue"
                      variant="outline"
                      size={"sm"}
                      onClick={() => toggleDialog(row.table)}
                    >
                      View order
                    </Button>
                  </Td>
                  <Modal
                    isCentered
                    isOpen={isModalOpenForTable === row.table}
                    onClose={() => {
                      toggleDialog(row.table);
                      setSelectedTable(null);
                    }}
                    size={"4xl"}
                  >
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader>Order Details</ModalHeader>
                      <ModalCloseButton />
                      <Table size={mobileView ? "sm" : "md"} width="full">
                        <Thead>
                          <Tr>
                            <Th>Item Name</Th>
                            <Th>Qty</Th>
                            <Th>Price</Th>
                            {user.department !== "Chef" && <Th>Delivered</Th>}
                            {user.department !== "Waiter" && <Th>Prepared</Th>}
                            {user.department !== "Chef" && <Th>Actions</Th>}
                          </Tr>
                        </Thead>
                        <Tbody>
                          {row?.cartItems?.map((item) =>
                            user.department === "Chef" &&
                            user.category === item.item.category ? (
                              <Tr key={item.item.name}>
                                <Td>{item.item.name}</Td>
                                <Td>{item.quantity}</Td>
                                <Td>Rs. {item.itemPrice}</Td>
                                {user.department !== "Waiter" && (
                                  <Td>
                                    <InputField
                                      type={"checkbox"}
                                      inputClass={"text-green-500"}
                                      value={item.isPrepared}
                                      onChange={() =>
                                        handleMarkAsPrepared(
                                          row?._id,
                                          item?.item?._id,
                                          item?.quantityOptionId
                                        )
                                      }
                                    />
                                  </Td>
                                )}
                                {user.department !== "Chef" && (
                                  <>
                                    <Td>
                                      <InputField
                                        type={"checkbox"}
                                        inputClass={"text-green-500"}
                                        value={item.isDelivered}
                                        onChange={() =>
                                          handleMarkAsDelivered(
                                            row?._id,
                                            item?.item?._id,
                                            item?.quantityOptionId
                                          )
                                        }
                                      />
                                    </Td>
                                  </>
                                )}
                                {user.department !== "Chef" && (
                                  <Td>
                                    <MyButton
                                      variant="link"
                                      colorScheme="blue"
                                      size="sm"
                                      onClick={() => removeFromCart(item)}
                                      bypassRoleCheck
                                    >
                                      Remove
                                    </MyButton>
                                  </Td>
                                )}
                              </Tr>
                            ) : (
                              user.department !== "Chef" && (
                                <Tr key={item.item.name}>
                                  <Td>{`${item.item.name} (${item.quantityQuantity} ${item.quantityUnit})`}</Td>
                                  <Td>{item.quantity}</Td>
                                  <Td>Rs. {item.itemPrice}</Td>
                                  {user.department !== "Chef" && (
                                    <Td>
                                      <InputField
                                        type={"checkbox"}
                                        inputClass={"text-green-500"}
                                        value={item.isDelivered}
                                        onChange={() =>
                                          handleMarkAsDelivered(
                                            row?._id,
                                            item?.item?._id,
                                            item?.quantityOptionId
                                          )
                                        }
                                      />
                                    </Td>
                                  )}
                                  {user.department !== "Waiter" && (
                                    <Td>
                                      <InputField
                                        type={"checkbox"}
                                        inputClass={"text-green-500"}
                                        value={item.isPrepared}
                                        onChange={() =>
                                          handleMarkAsPrepared(
                                            row?._id,
                                            item?.item?._id,
                                            item?.quantityOptionId
                                          )
                                        }
                                      />
                                    </Td>
                                  )}
                                  <Td>
                                    <MyButton
                                      variant="link"
                                      colorScheme="blue"
                                      size="sm"
                                      onClick={() => removeFromCart(item)}
                                      bypassRoleCheck
                                    >
                                      Remove
                                    </MyButton>
                                  </Td>
                                </Tr>
                              )
                            )
                          )}
                          {row?.instructions && (
                            <Tr key={"total"}>
                              <Td colSpan={2}>
                                <b>Instructions : </b>
                              </Td>
                              <Td colSpan={2}>{row?.instructions}</Td>
                            </Tr>
                          )}
                          <Tr key={"total"}>
                            <Td colSpan={2}>
                              <b>Total Price :</b>
                            </Td>
                            <Td colSpan={2}>
                              Rs. {row?.bill?.totalAmount} + Rs.
                              {row?.waiterTip || 0} (tip) =
                              {row?.bill?.totalAmount + (row?.waiterTip || 0)}{" "}
                            </Td>
                          </Tr>
                        </Tbody>

                        {user.department !== "Chef" && (
                          <div className="flex m-1 ">
                            <Button
                              variant={"outline"}
                              bgColor={"orange.300"}
                              className="m-1"
                              onClick={() => {
                                setIsAddItemModalOpen(true);
                                setSelectedTable(row.table);
                              }}
                            >
                              Add Item
                            </Button>

                            {row.isCashPaymentRequested && (
                              <Button
                                variant={"outline"}
                                bgColor={"orange.300"}
                                className="m-1"
                                onClick={() => markAsPaidForCash(row._id)}
                              >
                                Mark as Paid
                              </Button>
                            )}
                          </div>
                        )}
                      </Table>
                    </ModalContent>
                  </Modal>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}

      {/* Add Item Drawer */}
      <Drawer
        placement="right"
        isOpen={isAddItemModalOpen}
        onClose={toggleAddItemModal}
        zIndex={2}
      >
        <DrawerOverlay />
        <DrawerContent overflowY="auto">
          <DrawerHeader>Add Item</DrawerHeader>
          <DrawerCloseButton />
          <Table size="md" width="full">
            <Thead>
              <Tr>
                <Th>Item Name</Th>
                <Th>Prices</Th>
              </Tr>
            </Thead>
            <Tbody>
              {availableItems.map((item, index) => (
                <Tr key={index}>
                  <Td>{item.name}</Td>
                  <Td>
                    {item?.quantityOptions.map((qtyOption) => {
                      return (
                        <>
                          <div>{`${qtyOption.quantity} ${qtyOption.quantityUnit} ${qtyOption.price}`}</div>
                          <div>
                            <Button
                              colorScheme="green"
                              size="sm"
                              onClick={() => addItem(item, qtyOption)}
                            >
                              Add
                            </Button>
                          </div>
                        </>
                      );
                    })}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </DrawerContent>
      </Drawer>
    </div>
  );
};

export default Orders;
