import http from "./http";

const restaurantApi = "/restaurant/menu";

export const createMenu = (data, query = "") => {
  return http.post(`${restaurantApi}/create${query}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getAllMenu = async (query = "") => {
  const res = await http.get(`${restaurantApi}/all${query}`);
  return res;
};

export const getAllFoodCategories = async (query = "") => {
  const res = await http.get(`${restaurantApi}/categories${query}`);
  return res;
};

export const deleteMenu = async (query) => {
  return http.delete(`${restaurantApi}/delete${query}`);
};

export const updateMenu = async (data, query) => {
  return http.put(`${restaurantApi}/update${query}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getDefaultMenu = async () => {
  const res = await http.get(`${restaurantApi}/default/all`);
  return res;
};

export const importMenu = async (data) => {
  return http.post(`${restaurantApi}/create/bulk`, data);
};
