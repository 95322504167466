import React, { useState } from "react";
import Select from "react-select";
import { FaCloudUploadAlt, FaEye, FaEyeSlash } from "react-icons/fa";
import { BsFillInfoCircleFill } from "react-icons/bs";
import {
  ChakraProvider,
  HStack,
  Button,
  Image,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
} from "@chakra-ui/react";
const InputField = ({
  labelName,
  type,
  name,
  placeholder,
  onChange,
  value,
  touched,
  error,
  onBlur,
  disabled,
  rowWise,
  inputClass,
  labelClass,
  min,
  options,
  multiple,
  accept,
  info,
  isRequired,
  infoTitle,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Check if value is undefined, 'undefined', null, or empty string
  const shouldDisplayValue =
    value !== undefined &&
    value !== "undefined" &&
    value !== null &&
    value !== "";

  return (
    <div className="">
      <div
        className={`col-span-1  ${
          rowWise ? "grid grid-cols-3" : "flex flex-col"
        } `}
      >
        <label
          className={`  col-span-1 flex items-center ${labelClass}`}
          htmlFor={name}
        >
          {labelName}
          {isRequired && <span className="text-red-500">*</span>}
        </label>
        {type === "file" ? (
          <div
            className={`relative border-[1px] p-2 bg-[#F6F1EE] rounded-xs cursor-pointer w-full ${
              disabled ? "bg-gray-300" : ""
            } ${inputClass}`}
          >
            <input
              type={type}
              id={name}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              className="absolute opacity-0 h-full w-full cursor-pointer"
              multiple={multiple}
              accept={accept}
              required={isRequired}
            />
            <div className="border-dashed border-2 p-4 flex justify-center items-center gap-4">
              <FaCloudUploadAlt size={80} />
              <p className="text-lg">Upload File</p>
            </div>
          </div>
        ) : type === "password" ? (
          // Password input field with eye icon to toggle visibility
          <div className="relative">
            <input
              disabled={disabled}
              value={value}
              onChange={onChange}
              className={`border-[1px] p-2 bg-[#F6F1EE] rounded-xs w-full ${
                disabled ? "bg-gray-300" : ""
              } ${inputClass}`}
              placeholder={placeholder}
              type={showPassword ? "text" : "password"}
              id={name}
              name={name}
              required={isRequired}
              onBlur={onBlur}
            />

            <div
              className="absolute top-0 right-0 bottom-0 flex items-center px-2 cursor-pointer"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </div>
          </div>
        ) : type === "select" ? (
          <Select
            isDisabled={disabled}
            value={options?.find((option) => option.value === value)}
            onChange={(selectedOption) => onChange(selectedOption)}
            options={options}
            styles={{
              control: (provided) => ({
                ...provided,
                padding: "2px",
                borderRadius: "8px",
              }),
            }}
            className={`border-0 bg-[#F6F1EE] ${
              disabled ? "bg-gray-300" : ""
            } ${inputClass}`}
            placeholder={placeholder}
            onBlur={onBlur}
            required={isRequired}
          />
        ) : type === "textarea" ? (
          <div className="flex items-center gap-2">
            <textarea
              disabled={disabled}
              value={value}
              onChange={onChange}
              className={`bg-[#F6F1EE] rounded-[25px] shadow border-opacity-20 p-2 w-full${
                disabled ? "bg-gray-300" : ""
              } ${inputClass}`}
              placeholder={placeholder}
              type={type}
              id={name}
              cols={5}
              required={isRequired}
              name={name}
              onBlur={onBlur}
              style={{ height: "77px" }}
            />

            {info && (
              <BsFillInfoCircleFill size={20} color="#666" title={infoTitle} />
            )}
          </div>
        ) : type === "checkbox" ? (
          <div className="flex items-center ">
            <input
              disabled={disabled}
              min="0"
              checked={value}
              onChange={onChange}
              className={`border-[1px]  bg-[#F6F1EE] rounded-xs  ${
                disabled ? "bg-gray-300" : ""
              } `}
              placeholder={placeholder}
              type={type}
              id={name}
              name={name}
              required={isRequired}
              onBlur={onBlur}
            />
          </div>
        ) : (
          <div className="flex items-center gap-2">
            <input
              disabled={disabled}
              value={value}
              min="0"
              onChange={onChange}
              className={`border-[1px] p-2 bg-[#F6F1EE] rounded-xs w-full ${
                disabled ? "bg-gray-300" : ""
              } ${inputClass}`}
              placeholder={placeholder}
              type={type}
              id={name}
              name={name}
              required={isRequired}
              onBlur={onBlur}
            />

            {info && (
              <div className="relative">
                <Tooltip label={infoTitle} placement="top" offset={[0, 8]}>
                  <Popover trigger="hover" placement="right" offset={[0, 28]}>
                    <PopoverTrigger>
                      <BsFillInfoCircleFill
                        size={20}
                        color="#666"
                        title={infoTitle}
                        className="title"
                        data-title="test"
                      />
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverArrow />
                      <h1 className="text-lg">{infoTitle}</h1>
                    </PopoverContent>
                  </Popover>
                </Tooltip>
              </div>
            )}
          </div>
        )}
      </div>
      {shouldDisplayValue && touched && error && (
        <p className="text-red-500 ">{error}</p>
      )}
    </div>
  );
};

export default InputField;
