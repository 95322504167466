import React, { useState } from "react";
import { FaTrash, FaTrashAlt } from "react-icons/fa";
import Gap from "../../../UI/Gap";
import InputField from "../../../UI/InputField";
import { ROLES, menuFields } from "../../../constants/constant";
import MyButton from "../../../UI/MyButton";
import { useSelector } from "react-redux";

const MenuForm = ({
  loading,
  item,
  images,
  imagePreviews,
  handleImageUpload,
  removeImage,
  handleInputChange,
  handleSelectChange,
  handleCheckboxChange,
  createMenuItem,
  isItemEdit,
  setQuantityOptions,
  quantityOptions,
  addQuantityInput,
  removeQuantityInput,
}) => {
  const settings = useSelector((state) => state.settings);
  const variants = settings.variants;
  const categories = settings.categories;
  const quantityUnits = settings.quantityUnits;

  return (
    <form className="rounded-2xl p-3" onSubmit={createMenuItem}>
      {/* <Gap>Menu Item Details</Gap> */}
      <div className="flex flex-col lg:flex-row lg:space-x-8">
        <div className="lg:w-1/2">
          <div className="flex gap-4 flex-col">
            {menuFields?.slice(0, 3).map((field, index) => {
              return (
                <InputField
                  key={index}
                  labelName={field.label}
                  inputClass={
                    "h-10 bg-[#F6F1EE] rounded-lg  border border-black border-opacity-20"
                  }
                  type={field.type}
                  name={field.name}
                  value={item[field.name]} // Update this line
                  options={
                    field.name === "variant"
                      ? variants.map((vari) => {
                          return {
                            label: vari.name,
                            value: vari.name,
                          };
                        })
                      : field.name === "category"
                      ? categories.map((vari) => {
                          return {
                            label: vari.name,
                            value: vari.name,
                          };
                        })
                      : quantityUnits.map((vari) => {
                          return {
                            label: vari.name,
                            value: vari.name,
                          };
                        })
                  }
                  isRequired={field.isRequired}
                  labelClass={"text-black  mb-1 font-semibold"}
                  onChange={
                    field.type === "select"
                      ? (selectedValue) =>
                          handleSelectChange(field.name, selectedValue)
                      : field.type === "checkbox"
                      ? (e) =>
                          handleCheckboxChange(field.name, e.target.checked)
                      : (e) => handleInputChange(field.name, e.target.value)
                  }
                />
              );
            })}
          </div>
          <div className="flex gap-4 flex-col justify-center">
            {quantityOptions.map((quant, index) => (
              <div key={index} className="flex gap-4">
                <InputField
                  labelName={`Quantity ${index + 1}`}
                  inputClass="h-10 bg-[#F6F1EE] rounded-lg  border border-black border-opacity-20"
                  type="text"
                  name={`quantityOptions-${index}`}
                  value={quant.quantity}
                  onChange={(e) => {
                    const newQuantity = [...quantityOptions];
                    newQuantity[index].quantity = e.target.value;
                    setQuantityOptions(newQuantity);
                  }}
                />
                <InputField
                  labelName={`Quantity Unit ${index + 1}`}
                  inputClass="h-10 bg-[#F6F1EE] rounded-lg  border border-black border-opacity-20"
                  type="select"
                  name={`quantity-unit-${index}`}
                  value={quant.quantityUnit}
                  options={quantityUnits.map((item) => {
                    return {
                      label: item.name,
                      value: item.name,
                    };
                  })}
                  onChange={({ value }) => {
                    const newQuantity = [...quantityOptions];
                    newQuantity[index].quantityUnit = value; // Parse the input value to a number
                    setQuantityOptions(newQuantity);
                  }}
                />
                <InputField
                  labelName={`Price ${index + 1}`}
                  inputClass="h-10 bg-[#F6F1EE] rounded-lg  border border-black border-opacity-20"
                  type="number" // Change the type to "number"
                  name={`price-${index}`}
                  value={quant.price.toString()} // Convert price to string
                  onChange={(e) => {
                    const newQuantity = [...quantityOptions];
                    newQuantity[index].price = parseFloat(e.target.value); // Parse the input value to a number
                    setQuantityOptions(newQuantity);
                  }}
                />
                <FaTrashAlt
                  size={28}
                  className="mt-6"
                  color="red"
                  onClick={() => removeQuantityInput(index)}
                />
              </div>
            ))}
            <MyButton
              className="bg-primary text-white rounded-xl p-2"
              type="button"
              onClick={addQuantityInput}
              bypassRoleCheck
            >
              Add Quantity
            </MyButton>
          </div>

          <div className="mt-2 z-0">
            {/* <Gap>Add Images</Gap> */}
            <div className="flex justify-center gap-4 p-3 -2xl flex-col z-0">
              <InputField
                id="profile_pic"
                hidden
                type="file"
                onChange={handleImageUpload}
                accept="image/png, image/jpeg"
                multiple
              />
              <div className="flex justify-evenly flex-wrap">
                {imagePreviews.map((preview, index) => (
                  <div key={index} className="relative flex gap-3">
                    <img
                      key={index}
                      src={preview}
                      alt={`preview-${index}`}
                      className="aspect-square w-[200px] mt-2 lg:mt-0"
                    />
                    <MyButton
                      className="absolute top-0 right-0 p-1 bg-red-500 text-white rounded-full"
                      onClick={() => removeImage(index)}
                      type="button"
                      allowedRoles={[ROLES.SUPER_ADMIN, ROLES.RESTAURANT_ADMIN]}
                    >
                      <FaTrash />
                    </MyButton>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Right Side - Second Half */}
        <div className="lg:w-1/2">
          {/* <Gap>Remaining Menu Item Details</Gap> */}
          <div className="flex gap-4 flex-col ">
            {menuFields?.slice(3, menuFields.length).map((field, index) => (
              <InputField
                key={index}
                isRequired={field.isRequired}
                labelName={field.label}
                inputClass={`h-10 bg-[#F6F1EE] rounded-lg border border-black border-opacity-20`}
                type={field.type}
                name={field.name}
                value={item[field.name]}
                options={
                  field.name === "variant"
                    ? variants.map((vari) => {
                        return {
                          label: vari.name,
                          value: vari.name,
                        };
                      })
                    : field.name === "category"
                    ? categories.map((vari) => {
                        return {
                          label: vari.name,
                          value: vari.name,
                        };
                      })
                    : quantityUnits.map((vari) => {
                        return {
                          label: vari.name,
                          value: vari.name,
                        };
                      })
                }
                labelClass={"text-black mb-1 font-semibold"}
                onChange={
                  field.type === "select"
                    ? (selectedValue) =>
                        handleSelectChange(field.name, selectedValue)
                    : field.type === "checkbox"
                    ? (e) => handleCheckboxChange(field.name, e.target.checked)
                    : (e) => handleInputChange(field.name, e.target.value)
                }
              />
            ))}

            <MyButton
              className="bg-primary text-white rounded-xl p-2"
              type="submit"
              loading={loading}
              allowedRoles={[ROLES.SUPER_ADMIN, ROLES.RESTAURANT_ADMIN]}
            >
              {isItemEdit ? "Update" : "Add"}
            </MyButton>
          </div>
        </div>
      </div>
    </form>
  );
};

export default MenuForm;
