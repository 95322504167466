import React from "react";

const CategoryCards = ({ category, isActive, ...props }) => {
  return (
    <div
      className={`text-ellipsis mb-4 bg-gray-100 w-52 h-[4.3rem] flex justify-start border-2 cursor-pointer rounded-[2px] border-gray-50 p-2 gap-3 items-center ${
        isActive ? "border-yellow-200 bg-yellow-50" : ""
      }`}
      {...props}
    >
      <div className="w-[20%]">
        <img
          src={category.thumbnail}
          alt={`${category._id}`}
          className="w-12 aspect-square object-cover rounded-full"
        />
      </div>
      <div className="max-w-[70%]">
        <h2
          className={`w-[100%] overflow-hidden truncate font-semibold ${
            isActive ? "text-blue-800" : "text-slate-800"
          }`}
        >
          {category._id}
        </h2>
        <h2 className="font-semibold text-[.6rem]">{category.count} items</h2>
      </div>
    </div>
  );
};

export default CategoryCards;
