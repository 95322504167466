import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import MyButton from "../../../UI/MyButton";
import { ROLES } from "../../../constants/constant";
import CommonModal from "../../../UI/CommonModal";
import { getDefaultMenu, importMenu } from "../../../services/menuApi";
import toast from "react-hot-toast";
import { getErrorMessage } from "../../../utils/functions";

const ImportMenu = ({ setShowImportMenu, setReload }) => {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [importingLoading, setImportingLoading] = useState(false);
  const handleSelectItems = (item) => {
    //if already selected then remove from selectedItems
    if (selectedItems.includes(item)) {
      setSelectedItems(selectedItems.filter((i) => i !== item));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };
  useEffect(() => {
    const getDefaultMenuItems = async () => {
      setLoading(true);
      const res = await getDefaultMenu();
      console.log(res);
      setItems(res);
      setLoading(false);
    };
    getDefaultMenuItems();
  }, []);
  const isSelected = (item) => {
    return selectedItems.includes(item);
  };
  const handleSelectAllItems = () => {
    //if already selected then remove from selectedItems
    if (selectedItems.length === items.length) {
      setSelectedItems([]);
    } else {
      setSelectedItems(items);
    }
  };

  const handleImportMenu = async () => {
    try {
      setImportingLoading(true);
      //remote _id from selectedItems
      const res = await importMenu(
        selectedItems.map((item) => ({
          ...item,
          _id: undefined,
        }))
      );
      setReload((prev) => !prev);
      toast.success("Menu imported successfully");
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setImportingLoading(false);
      setShowImportMenu(false);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (importingLoading) return <div>Importing...</div>;
  //image , item name
  return (
    <>
      <div className="flex justify-center items-center gap-2 flex-wrap w-full">
        <button
          type="button"
          className="text-sm text-indigo-600 font-medium px-4 py-2 rounded-full bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mb-4 flex-1"
          onClick={() => handleSelectAllItems()}
          disabled={importingLoading || loading}
        >
          {selectedItems.length === items.length
            ? "Deselect All"
            : "Select All"}
        </button>
        <button
          type="button"
          className="text-sm text-red-600 font-medium px-4 py-2 rounded-full bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 mb-4 flex-1"
          onClick={() => handleImportMenu()}
          disabled={selectedItems.length === 0 || importingLoading || loading}
        >
          Import
        </button>
      </div>
      <div class="grid lg:grid-cols-5 grid-cols-2 gap-2">
        {items.map((item, index) => (
          <div
            key={index}
            className="flex flex-wrap  flex-col gap-2 p-1"
            onClick={() => handleSelectItems(item)}
            style={{
              backgroundColor: isSelected(item) ? "#bcd3f7" : "white",
              border: isSelected(item)
                ? "1px solid #bcd3f7"
                : "1px solid white",
            }}
          >
            <img
              src={item.thumbnail}
              alt="Food image"
              className="aspect-square h-48 object-cover rounded-t-lg"
            />
            <div className="p-1 flex flex-col justify-between">
              <p className="text-lg font-semibold text-gray-800 mb-2 truncate">
                {item.name}
              </p>
              <button
                type="button"
                className="text-sm text-indigo-600 font-medium px-4 py-2 rounded-full bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => handleSelectItems(item)}
              >
                {isSelected(item) ? "Remove" : "Select"}
              </button>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

const MenuActions = ({
  showMenu,
  totalItems,
  handleSetShowMenu,
  setReload,
}) => {
  const [showImportMenu, setShowImportMenu] = useState(false);
  const handleSetShowImportMenu = () => {
    setShowImportMenu(true);
  };
  return (
    <div className="flex justify-end p-4">
      <CommonModal
        title="Import Menu"
        size="6xl"
        isOpen={showImportMenu}
        onClose={() => setShowImportMenu(false)}
      >
        <ImportMenu
          setShowImportMenu={setShowImportMenu}
          setReload={setReload}
        />
      </CommonModal>

      {showMenu ? (
        <MyButton
          className="pl-2 pr-2 p-1 text-white rounded-[10px] bg-slate-800 font-semibold flex justify-center items-center gap-1"
          onClick={handleSetShowMenu}
          allowedRoles={[ROLES.SUPER_ADMIN, ROLES.RESTAURANT_ADMIN]}
        >
          View Items
        </MyButton>
      ) : (
        <div className="w-full flex justify-between items-center">
          <p className="ml-3 font-semibold text-lg">
            Total items ({totalItems})
          </p>
          <div className="flex justify-center items-center gap-2">
            <MyButton
              className="pl-2 pr-2 p-1 text-white rounded-[10px] bg-slate-800 font-semibold flex justify-center items-center gap-1"
              onClick={handleSetShowImportMenu}
              allowedRoles={[ROLES.SUPER_ADMIN, ROLES.RESTAURANT_ADMIN]}
            >
              <FaPlus fontWeight={"200"} /> Import Menu
            </MyButton>
            <MyButton
              className="pl-2 pr-2 p-1 text-white rounded-[10px] bg-slate-800 font-semibold flex justify-center items-center gap-1"
              onClick={handleSetShowMenu}
              allowedRoles={[ROLES.SUPER_ADMIN, ROLES.RESTAURANT_ADMIN]}
            >
              <FaPlus fontWeight={"200"} /> Add item
            </MyButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default MenuActions;
