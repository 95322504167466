import React from "react";

const VerificationPending = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <h1 className="text-3xl font-bold mb-4 text-center">
        Contact Admin for Verification
      </h1>
      <p className="text-lg mb-8 text-center">
        Please contact the admin to complete the verification process.
      </p>
      <h1>Verification is pending</h1>
    </div>
  );
};

export default VerificationPending;
