import http from "./http";

const employeeApi = "/restaurant/employee";

export const addEmployee = (data, query = "") => {
  return http.post(`${employeeApi}/create${query}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getAllEmployees = async () => {
  const res = await http.get(`${employeeApi}/all`);
  return res;
};

export const deleteEmployee = async (params) => {
  return http.delete(`${employeeApi}/delete/${params}`);
};

export const updateEmployee = async (data, params) => {
  return http.put(`${employeeApi}/update/${params}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
