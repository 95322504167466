import React from "react";
import Cards from "./Cards";

const Skeleton = () => {
  return (
    <div className="flex w-full justify-evenly gap-2 p-2 flex-wrap gap-x-5">
      {Array.from({ length: 12 }).map((_, index) => (
        <Cards key={index} />
      ))}
    </div>
  );
};

export default Skeleton;
