import { Plus } from "mdi-material-ui";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Gap from "../../UI/Gap";
import MyButton from "../../UI/MyButton";
import {
  addIntegration as createIntegration,
  getIntegration,
} from "../../services/integrationApi";
import { getErrorMessage } from "../../utils/functions";
import InputField from "../../UI/InputField";
import { useSelector } from "react-redux";
import Loader from "../../UI/Loader";

const Integrations = () => {
  const [newIntegration, setNewIntegration] = useState({
    apiKey: "",
    apiSecret: "",
    webhookSecret: "",
  });
  const [loading, setLoading] = useState(false);

  const user = useSelector((state) => state.auth.user);
  const restaurant = user.restaurant._id;

  const handleNewIntegrationChange = (event, attribute) => {
    setNewIntegration({ ...newIntegration, [attribute]: event.target.value });
  };

  const getNewIntegration = async () => {
    try {
      setLoading(true);
      const res = await getIntegration(
        `?type=razorpay&restaurant=${restaurant}`
      );
      if (res) {
        setNewIntegration({
          apiKey: res?.secretData?.apiKey || "",
          apiSecret: res?.secretData?.apiSecret || "",
          webhookSecret: res?.secretData?.webhookSecret || "",
        });
      }
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const addIntegration = async () => {
    try {
      setLoading(true);
      await createIntegration({
        type: "razorpay",
        secretData: {
          apiKey: newIntegration.apiKey,
          apiSecret: newIntegration.apiSecret,
          webhookSecret: newIntegration.webhookSecret,
        },
      });
      toast.success("Updated");
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getNewIntegration();
  }, []);

  if (loading) return <Loader />;

  return (
    <div className="flex justify-start p-2 flex-col gap-2">
      <div className="p-2 border rounded border-[#b6b6b6]">
        <div className="text-center text-lg py-4">Razorpay</div>
        <div className="flex flex-col gap-2">
          <InputField
            value={newIntegration.apiKey}
            onChange={(e) => handleNewIntegrationChange(e, "apiKey")}
            placeholder="API KEY"
            inputClass="p-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
          />
          <InputField
            value={newIntegration.apiSecret}
            onChange={(e) => handleNewIntegrationChange(e, "apiSecret")}
            placeholder="API SECRET"
            inputClass="p-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
          />
          <InputField
            value={newIntegration.webhookSecret}
            onChange={(e) => handleNewIntegrationChange(e, "webhookSecret")}
            placeholder="WEBHOOK SECRET"
            inputClass="p-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
          />
          <MyButton
            className="p-2 text-white rounded-[10px] bg-slate-800 font-semibold flex justify-center items-center gap-1 h-10"
            variant="primary"
            colorScheme="blue"
            size="sm"
            bypassRoleCheck
            onClick={addIntegration}
          >
            <Plus />
            Add Integration
          </MyButton>
        </div>
      </div>
    </div>
  );
};

export default Integrations;
