import { City } from "country-state-city";
import moment from "moment";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { FaTrash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import InputField from "../../../UI/InputField";
import MyButton from "../../../UI/MyButton";
import { ROLES, restaurantFields } from "../../../constants/constant";
import { updateUser } from "../../../redux/authSlice";
import { addRestaurant } from "../../../services/restaurantApi";
import {
  appendIfDefined,
  createImagePreview,
  getErrorMessage,
  validateFields,
} from "../../../utils/functions";

const VerificationForm = () => {
  const [verificationDetails, setVerificationDetails] = useState({});
  const [images, setImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isVerificationDetailsEdit, setIsVerificationDetailsEdit] =
    useState(false);

  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const handleInputChange = (field, value) => {
    setVerificationDetails((prevItem) => ({
      ...prevItem,
      [field]: value,
    }));
  };

  const handleSelectChange = (field, selectedValue) => {
    if (field === "state") {
      const selectedState = selectedValue.value;
      const cities = City.getCitiesOfState("IN", selectedState);
      const firstCity = cities.length > 0 ? cities[0].name : "";
      setVerificationDetails((prevItem) => ({
        ...prevItem,
        [field]: selectedValue.value,
        city: firstCity,
      }));
    } else {
      setVerificationDetails((prevItem) => ({
        ...prevItem,
        [field]: selectedValue.value,
      }));
    }
  };

  const removeImage = (index) => {
    const newImages = [...images];
    const newPreviews = [...imagePreviews];

    newImages.splice(index, 1);
    newPreviews.splice(index, 1);

    setImages(newImages);
    setImagePreviews(newPreviews);
  };
  const handleAddVerificationDetails = async (e) => {
    try {
      e.preventDefault();
      if (images?.length === 0) {
        return toast.error("Image is required");
      }

      const missingField = validateFields(
        [
          "name",
          "email",
          "street",
          "state",
          "city",
          "postalCode",
          "phone",
          "gst",
        ],
        verificationDetails
      );
      if (missingField) {
        return toast.error(missingField);
      }
      setLoading(true);

      const formData = new FormData();
      // Append text fields to FormData
      appendIfDefined(formData, "name", verificationDetails.name);
      appendIfDefined(formData, "freeTrialEndDate", moment());
      appendIfDefined(formData, "description", verificationDetails.description);
      const address = {
        city: verificationDetails.city,
        street: verificationDetails.street,
        state: verificationDetails.state,
        postalCode: verificationDetails.postalCode,
      };

      for (const key in address) {
        if (address.hasOwnProperty(key)) {
          appendIfDefined(formData, `address[${key}]`, address[key]);
        }
      }
      appendIfDefined(formData, "phone", verificationDetails.phone);
      appendIfDefined(formData, "email", verificationDetails.email);
      appendIfDefined(formData, "owner", user._id);
      appendIfDefined(formData, "gst", verificationDetails.gst);
      // Append image files to FormData
      images?.forEach((image, index) => {
        formData.append(`image`, image);
      });

      if (isVerificationDetailsEdit) {
        // await updateVerificationDetails(formData, `${verificationDetails._id}`);
        toast.success("VerificationDetails updated successfully!");
      } else {
        const res = await addRestaurant(formData);
        dispatch(
          updateUser({
            user: {
              ...user,
              restaurant: res._id,
            },
          })
        );
        toast.success("VerificationDetails added successfully!");
      }

      setVerificationDetails({});
      //   setShowVerificationDetails(false);
      setImages([]);
      setImagePreviews([]);
      setIsVerificationDetailsEdit(false);
      return;
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };
  const handleImageUpload = (e) => {
    // Commented out logic for multiple images
    // const newImages = [...images, ...e.target.files];
    // setImages(newImages);

    // Allowing only one image
    const newImages = [e.target.files[0]];
    setImages(newImages);

    const previewArray = newImages.map((image) => {
      return createImagePreview(image);
    });
    setImagePreviews(previewArray);
  };
  return (
    <div className="rounded-2xl p-3">
      <h1 className="text-3xl text-center font-semibold mb-3">
        Restaurant Details
      </h1>
      <form
        className="flex flex-col lg:flex-row lg:space-x-8"
        onSubmit={handleAddVerificationDetails}
      >
        <div className="lg:w-1/2">
          <div className="flex gap-4 flex-col">
            {restaurantFields?.slice(0, 3).map((field, index) => (
              <div key={index}>
                <InputField
                  labelName={field.label}
                  inputClass={
                    "h-10 bg-[#F6F1EE] rounded-lg  border border-black border-opacity-20"
                  }
                  type={field.type}
                  name={field.name}
                  value={verificationDetails[field.name]}
                  options={field.options}
                  labelClass={"text-black  mb-1 font-semibold"}
                  info={field.info}
                  infoTitle={field.infoTitle}
                  isRequired={field.isRequired}
                  onChange={
                    field.type === "select"
                      ? (selectedValue) =>
                          handleSelectChange(field.name, selectedValue)
                      : (e) => handleInputChange(field.name, e.target.value)
                  }
                />
              </div>
            ))}
          </div>

          <div className="mt-2 z-0">
            {/* <Gap>Add Images</Gap> */}
            <div className="flex justify-center gap-4 p-3 -2xl flex-col z-0">
              <InputField
                id="logo"
                hidden
                name={"logo"}
                type="file"
                onChange={handleImageUpload}
                accept="image/png, image/jpeg"
                labelName={"Logo"}
                labelClass={"text-black  mb-1 font-semibold"}
                isRequired
                // multiple
              />
              <div className="flex justify-evenly flex-wrap">
                {imagePreviews.map((preview, index) => (
                  <div key={index} className="relative flex gap-3">
                    <img
                      key={index}
                      src={preview}
                      alt={`preview-${index}`}
                      className="aspect-square w-[200px] mt-2 lg:mt-0"
                    />
                    <MyButton
                      className="absolute top-0 right-0 p-1 bg-red-500 text-white rounded-full"
                      onClick={() => removeImage(index)}
                      type="button"
                      allowedRoles={[ROLES.SUPER_ADMIN, ROLES.RESTAURANT_ADMIN]}
                    >
                      <FaTrash />
                    </MyButton>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Right Side - Second Half */}
        <div className="lg:w-1/2">
          {/* <Gap>Remaining Menu Item Details</Gap> */}
          <div className="flex gap-4 flex-col">
            {restaurantFields?.slice(3, 5).map((field, index) => (
              <InputField
                key={index}
                labelName={field.label}
                inputClass={
                  "h-10 bg-[#F6F1EE] rounded-lg  border border-black border-opacity-20"
                }
                type={field.type}
                name={field.name}
                value={verificationDetails[field.name]}
                options={field.options}
                labelClass={"text-black mb-1 font-semibold"}
                onChange={
                  field.type === "select"
                    ? (selectedValue) =>
                        handleSelectChange(field.name, selectedValue)
                    : (e) => handleInputChange(field.name, e.target.value)
                }
                info={field.info}
                infoTitle={field.infoTitle}
                isRequired={field.isRequired}
              />
            ))}
            <InputField
              label="City"
              type="select"
              name="city"
              options={City.getCitiesOfState(
                "IN",
                verificationDetails.state
              ).map((item) => {
                return {
                  label: item.name,
                  value: item.name,
                };
              })}
              // info={}
              // infoTitle={field.infoTitle}
              isRequired
              labelName={"City"}
              inputClass={
                "h-10 bg-[#F6F1EE] rounded-lg  border border-black border-opacity-20"
              }
              value={verificationDetails.city}
              labelClass={"text-black mb-1 font-semibold"}
              onChange={(selectedValue) =>
                handleSelectChange("city", selectedValue)
              }
            />
            {restaurantFields
              ?.slice(5, restaurantFields.length)
              ?.map((field, index) => (
                <InputField
                  key={index}
                  labelName={field.label}
                  inputClass={
                    "h-10 bg-[#F6F1EE] rounded-lg  border border-black border-opacity-20"
                  }
                  type={field.type}
                  name={field.name}
                  value={verificationDetails[field.name]}
                  options={field.options}
                  labelClass={"text-black mb-1 font-semibold"}
                  onChange={
                    field.type === "select"
                      ? (selectedValue) =>
                          handleSelectChange(field.name, selectedValue)
                      : (e) => handleInputChange(field.name, e.target.value)
                  }
                  info={field.info}
                  infoTitle={field.infoTitle}
                  isRequired={field.isRequired}
                />
              ))}
            <MyButton
              className="bg-primary text-white rounded-xl p-2"
              type="submmit"
              loading={loading}
              allowedRoles={[ROLES.SUPER_ADMIN, ROLES.RESTAURANT_ADMIN]}
            >
              {isVerificationDetailsEdit ? "Update" : "Submit"}
            </MyButton>
          </div>
        </div>
      </form>
    </div>
  );
};

export default VerificationForm;
