import { ERROR_MSG } from "../constants/constant";

export const getErrorMessage = (err) => {
  return err?.response?.data?.message || ERROR_MSG;
};

export const capitalizeFirstLetter = (string) => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
};

export const createImagePreview = (image) => {
  const objectURL = URL.createObjectURL(image);
  return objectURL;
};

export const createImagePreviewFromUrl = (imageUrl) => {
  const image = new Image();
  image.src = imageUrl;
  return new Promise((resolve, reject) => {
    image.onload = () => resolve(image.src);
    image.onerror = (error) => reject(error);
  });
};

export const createImageFileFromUrl = async (imageUrl, fileName) => {
  const response = await fetch(imageUrl);
  const blob = await response.blob();
  const file = new File([blob], fileName, { type: blob.type });
  return file;
};

export const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const validateFields = (requiredFields, values) => {
  const missingFields = [];
  for (const field of requiredFields) {
    if (!values[field]) {
      missingFields.push(splitFieldName(field));
    }
  }
  if (missingFields.length > 0) {
    return `Please enter ${missingFields.join(", ")}`;
  }
  return null; // No missing fields
};

export const splitFieldName = (fieldName) => {
  // Split camel case into separate words
  fieldName = fieldName?.toString();
  let splitName = fieldName?.replace(/([a-z])([A-Z])/g, "$1 $2");
  // Split underscore-separated names into separate words
  splitName = splitName?.replace(/_/g, " ");
  return capitalizeFirstLetter(splitName?.toLowerCase());
};

export const appendIfDefined = (formData, key, value) => {
  if (value !== undefined && value !== null && value !== "") {
    formData.append(key, value);
  }
};
