import http from "./http";

const integrationApi = "/restaurant-integration";

// Integration API functions
export const addIntegration = (data, query = "") => {
  return http.post(`${integrationApi}/create${query}`, data);
};

export const updateIntegration = (data, query = "") => {
  return http.put(`${integrationApi}/update${query}`, data);
};

export const getIntegration = async (query) => {
  const res = await http.get(`${integrationApi}${query}`);
  return res;
};
