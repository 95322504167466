import React from "react";
import MyButton from "../../../UI/MyButton";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { ROLES } from "../../../constants/constant";

const RestaurantActions = ({
  showRestaurant,
  handleSetShowRestaurant,
  totalRestaurants,
}) => {
  const navigate = useNavigate("/restaurants");
  return (
    <div className="flex justify-end p-4">
      {!showRestaurant ? (
        <MyButton
          className="pl-2 pr-2 p-1 text-white rounded-[10px] bg-slate-800 font-semibold flex justify-center items-center gap-1"
          onClick={() => navigate("/restaurants")}
          allowedRoles={[ROLES.SUPER_ADMIN]}
        >
          View Restaurants
        </MyButton>
      ) : (
        <div className="w-full flex justify-between items-center">
          <p className="ml-3 font-semibold text-lg">
            Total restaurants ({totalRestaurants})
          </p>
          <MyButton
            className="pl-2 pr-2 p-1 text-white rounded-[10px] bg-slate-800 font-semibold flex justify-center items-center gap-1"
            onClick={handleSetShowRestaurant}
            allowedRoles={[ROLES.SUPER_ADMIN]}
          >
            <FaPlus fontWeight={"200"} /> Add restaurants
          </MyButton>
        </div>
      )}
    </div>
  );
};

export default RestaurantActions;
