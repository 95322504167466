import { chakra } from "@chakra-ui/react";
import images from "../../constants/imageConstant";
export const Logo = (props) => (
  <chakra.img
    src={images.logo}
    alt="logo"
    className="aspect-auto w-28 rounded-2xl"
    {...props}
  />
);
