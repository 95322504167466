import { Button, CircularProgress } from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

const MyButton = (props) => {
  const { user } = useSelector((state) => state.auth);
  const { allowedRoles, bypassRoleCheck } = props;

  if (!bypassRoleCheck) {
    const isAuthorized = allowedRoles && allowedRoles.includes(user?.role);
    if (!isAuthorized) {
      return null;
    }
  }

  return (
    <Button {...props} colorScheme="bg-slate-800" disabled={props.loading}>
      {props.loading ? (
        <CircularProgress isIndeterminate size={6} />
      ) : (
        props.children
      )}
    </Button>
  );
};

MyButton.propTypes = {
  loading: PropTypes.bool,
  allowedRoles: PropTypes.arrayOf(PropTypes.string),
  bypassRoleCheck: PropTypes.bool,
};

export default MyButton;
