import http from "./http";

const userApi = "/auth";
const otpApi = "/user/verification";

export const register = (data) => {
  return http.post(`${userApi}/register`, data);
};

export const login = (data) => {
  return http.post(`${userApi}/login`, data);
};

export const getUser = () => {
  return http.get(`/user/get`);
};

export const logout = () => {
  return http.patch(`${userApi}/logout`);
};

export const sendOtp = (data) => {
  return http.post(`${otpApi}/sendOtp`, data);
};

export const verifyOtp = (data) => {
  return http.post(`${otpApi}/mobile/verify-otp`, data);
};
export const forgotPasswordSendOtp = (data) => {
  return http.post(`${otpApi}/mobile/send-otp`, data);
};

export const updatePassword = (data) => {
  return http.put(`${userApi}/update/password`, data);
};
