import { Button, Image } from "@chakra-ui/react";
import { saveAs } from "file-saver";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { FaDownload, FaPlus } from "react-icons/fa";
import { TbBoxMultiple7 } from "react-icons/tb";
import Skeleton from "../../Skeletons/Skeleton";
import CommonModal from "../../UI/CommonModal";
import ConfirmationModal from "../../UI/ConfirmationModal";
import InputField from "../../UI/InputField";
import MyButton from "../../UI/MyButton";
import table_blue from "../../assets/images/table_blue.png";
import table_green from "../../assets/images/table_green.png";
import table_orange from "../../assets/images/table_orange.png";
import { maxBulkTableRange, ROLES } from "../../constants/constant";
import {
  createBulkTable,
  createTable,
  deleteTable,
  getAllTable,
  getTableStats,
} from "../../services/tableApi";
import { getErrorMessage } from "../../utils/functions";
import TableCard from "./components/TableCard";

const Tables = ({}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tableIndex, setTableIndex] = useState(null);
  const [startRange, setStartRange] = useState(null);
  const [endRange, setEndRange] = useState(null);
  const [showBulkTableModal, setShowBulkTableModal] = useState(false);
  const [tables, setTables] = useState([]);
  const [tableStats, setTableStats] = useState({});

  const fetchAllTables = async () => {
    const res = await getAllTable();
    setTables(res);
  };

  const fetchAllTablesStats = async () => {
    const res = await getTableStats();
    setTableStats(res);
  };

  const downloadQrCodes = async () => {
    try {
      setLoading(true);

      const images = [];
      const columns = 4; // Number of columns in the grid

      // Loop through the tables array and fetch QR code links
      for (const table of tables) {
        const qrCodeLink = table.qrCodeLink;
        const tableIndex = table.index; // Add this line to get the table index

        // Skip tables without a QR code link
        if (!qrCodeLink) continue;

        // Create an image element for each QR code link with crossorigin attribute
        const img = new window.Image();
        img.crossOrigin = "Anonymous"; // Add crossorigin attribute
        img.src = qrCodeLink;

        // Wait for the image to load
        await new Promise((resolve, reject) => {
          img.onload = resolve;
          img.onerror = reject;
        });

        images.push({ img, tableIndex }); // Store both the image and the table index
      }

      if (images.length === 0) {
        toast.error("No QR codes found.");
        setLoading(false);
        return;
      }

      // Calculate the number of rows based on the number of columns
      const rows = Math.ceil(images.length / columns);

      // Create a new canvas to combine individual QR code images
      const combinedCanvas = document.createElement("canvas");
      const ctx = combinedCanvas.getContext("2d");

      // Set the combined canvas size based on the grid layout
      combinedCanvas.width = images[0].img.width * columns;
      combinedCanvas.height = images[0].img.height * rows;

      // Combine individual QR code images onto the combined canvas in a grid
      images.forEach(({ img, tableIndex }, index) => {
        const col = index % columns;
        const row = Math.floor(index / columns);

        // Draw the QR code image
        ctx.drawImage(img, col * img.width, row * img.height);

        // Draw the table index below the QR code
        ctx.fillStyle = "black";
        ctx.font = "14px Arial";
        ctx.textAlign = "center";
        ctx.fillText(
          `Table ${index + 1}`,
          col * img.width + img.width / 2,
          (row + 1) * img.height
        );
      });

      // Convert the combined canvas to a blob
      combinedCanvas.toBlob((blob) => {
        // Save the blob as a file using file-saver
        saveAs(blob, "qr_codes_grid.png");

        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      toast.error(getErrorMessage(error));
      setLoading(false);
    }
  };

  const tablesContainerRef = useRef(null);

  useEffect(() => {
    setLoading(true);
    Promise.all([fetchAllTables(), fetchAllTablesStats()]);
    setLoading(false);
  }, []);

  const createRestaurantTable = async () => {
    try {
      setLoading(true);

      await createTable({ index: tableIndex });

      toast.success("Table created successfully!");

      setLoading(false);
      setTableIndex(null);
      fetchAllTables();
      return;
    } catch (error) {
      toast.error(getErrorMessage(error));
      setLoading(false);
    }
  };

  const createBulkRestaurantTable = async () => {
    try {
      if (!startRange || !endRange)
        return toast.error("Please select start and end range");
      if (startRange > endRange)
        return toast.error("Start range should be less than end range");
      if (startRange === endRange)
        return toast.error("Start range should be less than end range");
      if (startRange < 0 || endRange < 0)
        return toast.error("Please enter valid range");
      if (endRange - startRange > maxBulkTableRange)
        return toast.error(
          `Range should be less than ${maxBulkTableRange}. You can create upto ${maxBulkTableRange} tables at a time.`
        );
      setLoading(true);
      await createBulkTable({
        index: tableIndex,
        startRange,
        endRange,
      });

      toast.success("Table created successfully!");
      setLoading(false);
      setTableIndex(null);
      fetchAllTables();
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
      setTableIndex(null);
      setShowBulkTableModal(false);
    }
  };

  const handleDelete = async (tableId) => {
    try {
      setLoading(true);
      await deleteTable(tableId);
      toast.success("Table deleted successfully!");
      setTables((prevTables) =>
        prevTables.filter((table) => table._id !== tableId)
      );
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Skeleton />;
  return (
    <>
      <CommonModal
        isOpen={showBulkTableModal}
        onClose={() => setShowBulkTableModal(false)}
        loading={loading}
        message={"Do you want to create bulk table?"}
      >
        <div className="flex items-center gap-2 mt-4">
          <InputField
            labelName={"Start Range"}
            id="startRange"
            type="number"
            min="0"
            value={startRange}
            onChange={(e) => setStartRange(e.target.value)}
            // className="h-10 rounded-lg border border-gray-300 px-3"
          />
          <InputField
            labelName={"End Range"}
            id="endRange"
            type="number"
            min="0"
            value={endRange}
            onChange={(e) => setEndRange(e.target.value)}
            // className="h-10 rounded-lg border border-gray-300 px-3"
          />
          <Button
            className="mt-6 p-2 text-white rounded-[10px] bg-slate-800 font-semibold flex justify-center items-center gap-1 h-10"
            variant="primary"
            colorScheme="blue"
            loading={loading}
            onClick={createBulkRestaurantTable}
            bypassRoleCheck
          >
            Create
          </Button>
        </div>
      </CommonModal>
      <ConfirmationModal
        isOpen={isOpen}
        loading={loading}
        message={"Do you want to add a table?"}
        onClose={() => setIsOpen(false)}
        onConfirm={() => createRestaurantTable()}
      >
        <div className="flex items-center gap-2 mt-4">
          <InputField
            labelName={"Table Number"}
            id="tableIndex"
            type="number"
            min="0"
            value={tableIndex}
            onChange={(e) => setTableIndex(e.target.value)}
            // className="h-10 rounded-lg border border-gray-300 px-3"
          />
        </div>
      </ConfirmationModal>

      <div className="bg-[#F6F1EE]" ref={tablesContainerRef}>
        <div className="flex justify-end p-4">
          <div className="w-full flex flex-col md:flex-row justify-between items-center">
            <div className=" flex items-center p-2 gap-4">
              <div className="flex flex-col justify-center items-center bg-gray-100 rounded w-[130px] h-[90px]">
                <span className="text-xl mb-2">Total</span>
                <div className="flex justify-center items-center gap-4">
                  <Image
                    src={table_orange}
                    className="rounded-lg aspect-square w-10 cursor-pointer"
                  />
                  <span className="text-black text-3xl font-bold">
                    {tableStats.totalTables || 0}
                  </span>
                </div>
              </div>
              <div className="flex flex-col justify-center items-center bg-gray-100 rounded w-[130px] h-[90px]">
                <span className="text-xl mb-2">Running</span>
                <div className="flex justify-center items-center gap-4">
                  <Image
                    src={table_blue}
                    className="rounded-lg aspect-square w-10 cursor-pointer"
                  />
                  <span className="text-black text-3xl font-bold">
                    {tableStats.runningTables || 0}
                  </span>
                </div>
              </div>
              <div className="flex flex-col justify-center items-center bg-gray-100 rounded w-[130px] h-[90px]">
                <span className="text-xl mb-2">Vacant</span>
                <div className="flex justify-center items-center gap-4">
                  <Image
                    src={table_green}
                    className="rounded-lg aspect-square w-10 cursor-pointer"
                  />
                  <span className="text-black text-3xl font-bold">
                    {tableStats.vacantTables || 0}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex justify-between items-center gap-4 md:mt-5">
              <MyButton
                className="pl-2 pr-2 p-1 text-white rounded-[10px] bg-slate-800 font-semibold flex justify-center items-center gap-1"
                onClick={() => setShowBulkTableModal(true)}
                bypassRoleCheck
              >
                <TbBoxMultiple7  fontWeight={"200"} /> Create Bulk Table
              </MyButton>
              <MyButton
                className="pl-2 pr-2 p-1 text-white rounded-[10px] bg-slate-800 font-semibold flex justify-center items-center gap-1"
                onClick={downloadQrCodes}
                bypassRoleCheck
              >
                <FaDownload fontWeight={"200"} /> Download QR Codes
              </MyButton>
              <MyButton
                className="pl-2 pr-2 p-1 text-white rounded-[10px] bg-slate-800 font-semibold flex justify-center items-center gap-1"
                onClick={() => setIsOpen(true)}
                allowedRoles={[ROLES.SUPER_ADMIN, ROLES.RESTAURANT_ADMIN]}
              >
                <FaPlus fontWeight={"200"} /> Add table
              </MyButton>
            </div>
          </div>
        </div>
        <div className="flex bg-[#F6F1EE] p-6 gap-8 flex-wrap justify-center ">
          {tables.map((table, index) => (
            <TableCard
              key={index}
              loading={loading}
              table={table}
              index={index + 1}
              handleDelete={() => handleDelete(table._id)}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default Tables;
