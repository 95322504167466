import http from "./http";

const integrationApi = "/payment";

// Integration API functions
export const capturePayment = (data) => {
  return http.post(`/webhook/frontend/capture`, data);
};
// Integration API functions
export const addSubscription = (data, query = "") => {
  return http.post(`${integrationApi}/subscribe${query}`, data);
};
export const createFreeTrialSubscription = (data, query = "") => {
  return http.post(`${integrationApi}/free-trial/subscribe${query}`, data);
};
