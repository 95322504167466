/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import Gap from "../../UI/Gap";
import MyButton from "../../UI/MyButton";
import {
  capturePayment,
  createFreeTrialSubscription,
  addSubscription as createSubscription,
} from "../../services/subscriptionApi";
import { getUser } from "../../services/userApi";
import { showRazorpayPaymentModal } from "../../utils/checkout";
import { updateUser } from "../../redux/authSlice";
import moment from "moment";
import { getErrorMessage } from "../../utils/functions";

const Subscriptions = () => {
  const dispatch = useDispatch();

  const { auth } = useSelector((state) => state);
  const { user } = auth;

  const [startingFreeTrialLoader, setStartingFreeTrialLoader] = useState(false);

  const paymentSuccessHandler = async (months) => {
    try {
      await capturePayment({ months });
      const userData = await getUser();
      if (userData) {
        dispatch(
          updateUser({
            user: userData,
          })
        );
      }
      if (userData) {
        return toast.success("💸 Payment Success 💸", {
          position: "top-center",
        });
      }
    } catch (error) {
      toast.error(getErrorMessage(error));
    }
  };

  const paymentFailedHandler = async (response) => {
    toast.error("Payment Failed");
  };

  const checkoutHandler = async (months) => {
    if (user?.restaurant?.isSubscriptionActive) return;

    try {
      const order = await createSubscription({ months: 3 });
      showRazorpayPaymentModal(
        order,
        () => paymentSuccessHandler(months),
        paymentFailedHandler
      );
    } catch (error) {
      toast.error(getErrorMessage(error));
    }
  };

  const checkoutFreeTrialHandler = async () => {
    if (user?.restaurant?.isFreeTrial) return;

    setStartingFreeTrialLoader(true);
    try {
      await createFreeTrialSubscription();
      const userData = await getUser();
      if (userData) {
        dispatch(
          updateUser({
            user: userData,
          })
        );
      }
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setStartingFreeTrialLoader(false);
    }
  };

  const planEndDate = user?.restaurant?.subscriptionDetails?.planEndDate;

  let remainingTime = "";
  if (planEndDate) {
    const endDate = moment(planEndDate);
    const currentDate = moment();
    const duration = moment.duration(endDate.diff(currentDate));
    const months = duration.months();
    const days = duration.days();

    remainingTime = `${months} months and ${days} days`;
  }

  return (
    <div className="flex justify-start p-2 flex-col gap-2">
      <div className="p-2 border rounded border-[#b6b6b6] lg:block flex lg:flex-row flex-col">
        <div className="flex lg:justify-center justify-evenly items-center mt-5 lg:flex-row flex-col gap-4">
          {!user?.restaurant?.isFreeTrial &&
            !user?.restaurant?.freeTrialEndDate &&
            !user?.restaurant?.isSubscriptionActive && (
              <div className="flex flex-col bg-black rounded-3xl">
                <div className="px-6 py-8 sm:p-10 sm:pb-6">
                  <div className="grid items-center justify-center w-full grid-cols-1 text-left">
                    <div>
                      <h2 className="text-lg font-medium tracking-tighter text-white lg:text-3xl">
                        3 months
                      </h2>
                      <p className="mt-2 text-sm text-gray-100">
                        Grow steadily and pizza.
                      </p>
                    </div>
                    <div className="mt-6">
                      <p>
                        <span className="text-5xl font-light tracking-tight text-white">
                          Free
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex px-6 pb-8 sm:px-8">
                  <button
                    onClick={checkoutFreeTrialHandler}
                    loading={startingFreeTrialLoader}
                    aria-describedby="tier-starter"
                    className="items-center justify-center w-full px-6 py-2.5 text-center text-black duration-200 bg-[#F6F1EE] border-2 border-white rounded-full nline-flex hover:bg-transparent hover:border-white hover:text-white focus:outline-none focus-visible:outline-white text-sm focus-visible:ring-white"
                    href="#"
                  >
                    Start free trial
                  </button>
                </div>
              </div>
            )}

          {(!user?.restaurant?.isFreeTrial ||
            (user?.restaurant?.isFreeTrial &&
              user?.restaurant?.freeTrialEndDate &&
              moment().isAfter(user?.restaurant?.freeTrialEndDate))) &&
            !user?.restaurant?.isSubscriptionActive && (
              <div className="flex lg:flex-row flex-col rounded-3xl gap-4">
                <div className="flex flex-col bg-black rounded-3xl">
                  <div className="px-6 py-8 sm:p-10 sm:pb-6">
                    <div className="grid items-center justify-center w-full grid-cols-1 text-left">
                      <div>
                        <h2 className="text-lg font-medium tracking-tighter text-white lg:text-3xl">
                          3 months
                        </h2>
                        <p className="mt-2 text-sm text-gray-100">
                          Grow steadily and pizza.
                        </p>
                      </div>
                      <div className="mt-6">
                        <p>
                          <span className="text-5xl font-light tracking-tight text-white">
                            500
                          </span>
                          <span className="text-base font-medium text-white">
                            {" "}
                            /mo{" "}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex px-6 pb-8 sm:px-8">
                    <button
                      onClick={() => checkoutHandler(3)}
                      aria-describedby="tier-starter"
                      className="items-center justify-center w-full px-6 py-2.5 text-center text-black duration-200 bg-[#F6F1EE] border-2 border-white rounded-full nline-flex hover:bg-transparent hover:border-white hover:text-white focus:outline-none focus-visible:outline-white text-sm focus-visible:ring-white"
                      href="#"
                    >
                      Subscribe
                    </button>
                  </div>
                </div>
                <div className="flex flex-col bg-black rounded-3xl">
                  <div className="px-6 py-8 sm:p-10 sm:pb-6">
                    <div className="grid items-center justify-center w-full grid-cols-1 text-left">
                      <div>
                        <h2 className="text-lg font-medium tracking-tighter text-white lg:text-3xl">
                          6 months
                        </h2>
                        <p className="mt-2 text-sm text-gray-100">
                          Grow steadily and pizza.
                        </p>
                      </div>
                      <div className="mt-6">
                        <p>
                          <span className="text-5xl font-light tracking-tight text-white">
                            500
                          </span>
                          <span className="text-base font-medium text-white">
                            {" "}
                            /mo{" "}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex px-6 pb-8 sm:px-8">
                    <button
                      onClick={() => checkoutHandler(6)}
                      aria-describedby="tier-starter"
                      className="items-center justify-center w-full px-6 py-2.5 text-center text-black duration-200 bg-[#F6F1EE] border-2 border-white rounded-full nline-flex hover:bg-transparent hover:border-white hover:text-white focus:outline-none focus-visible:outline-white text-sm focus-visible:ring-white"
                      href="#"
                    >
                      Subscribe
                    </button>
                  </div>
                </div>
                <div className="flex flex-col bg-black rounded-3xl">
                  <div className="px-6 py-8 sm:p-10 sm:pb-6">
                    <div className="grid items-center justify-center w-full grid-cols-1 text-left">
                      <div>
                        <h2 className="text-lg font-medium tracking-tighter text-white lg:text-3xl">
                          12 months
                        </h2>
                        <p className="mt-2 text-sm text-gray-100">
                          Grow steadily and pizza.
                        </p>
                      </div>
                      <div className="mt-6">
                        <p>
                          <span className="text-5xl font-light tracking-tight text-white">
                            500
                          </span>
                          <span className="text-base font-medium text-white">
                            {" "}
                            /mo{" "}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex px-6 pb-8 sm:px-8">
                    <button
                      onClick={() => checkoutHandler(12)}
                      aria-describedby="tier-starter"
                      className="items-center justify-center w-full px-6 py-2.5 text-center text-black duration-200 bg-[#F6F1EE] border-2 border-white rounded-full nline-flex hover:bg-transparent hover:border-white hover:text-white focus:outline-none focus-visible:outline-white text-sm focus-visible:ring-white"
                      href="#"
                    >
                      Subscribe
                    </button>
                  </div>
                </div>
              </div>
            )}
        </div>

        <div className="flex flex-col w-full gap-2 justify-center items-center">
          {user?.restaurant?.isFreeTrial &&
            moment().isBefore(user?.restaurant?.freeTrialEndDate) && (
              <>
                <div className="flex flex-col items-center justify-center bg-black rounded-3xl p-2">
                  <div className="px-6 py-8 sm:p-10 sm:pb-6">
                    <div className="grid items-center justify-center w-full grid-cols-1 text-left pt-14 pb-14 ">
                      <div>
                        <h2 className="text-lg font-medium tracking-tighter text-white lg:text-3xl">
                          3 months
                        </h2>
                        <p className="mt-2 text-sm text-gray-100">
                          Grow steadily and pizza.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="bg-green-100 p-1 px-5 rounded-lg mt-4">
                    Free Trial is active
                  </div>
                  <div className="flex justify-center items-center mt-5 text-md text-white pb-3 ">
                    <span className="">Trial valid till:</span>
                    <span className="font-semibold ml-2">
                      {moment(user?.restaurant?.freeTrialEndDate).format(
                        "DD-MM-YYYY"
                      )}
                    </span>
                  </div>
                </div>
              </>
            )}
          {user?.restaurant?.isSubscriptionActive && (
            <div className="flex flex-col justify-center items-center mt-2 text-md">
              <div className="flex flex-col bg-black rounded-3xl">
                <div className="px-6 py-8 sm:p-10 sm:pb-6">
                  <div className="grid items-center justify-center w-full grid-cols-1 text-left pt-14 pb-14">
                    <div>
                      <h2 className="text-lg font-medium tracking-tighter text-white lg:text-3xl">
                        {user?.restaurant?.subscriptionDetails?.months} months
                      </h2>
                      <p className="mt-2 text-sm text-gray-100">
                        Grow steadily and pizza.
                      </p>
                    </div>
                    <div className="mt-6">
                      <p>
                        <span className="text-5xl font-light tracking-tight text-white">
                          {user?.restaurant?.subscriptionDetails?.amountPaid}
                        </span>
                        <span className="text-base font-medium text-white">
                          {" "}
                          /mo{" "}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5 flex flex-col">
                {/* <span className="">Subscription valid till:</span> */}
                {/* <span className="font-semibold text-[#235A84] ml-2">
                  {moment(
                    user?.restaurant?.subscriptionDetails?.planEndDate
                  ).format("DD-MM-YYYY")}
                </span> */}
                {remainingTime && (
                  <p className="font-semibold text-[#235A84] ml-2 text-lg">
                    Your {user?.restaurant?.isFreeTrial ? "Free Trial" : "Plan"}{" "}
                    ends in {remainingTime}
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-center items-center w-full flex-col p-8">
        <h1 className="text-3xl font-semibold text-center">
          You can leverage following features for your benefit
        </h1>
        <div className="flex  w-2/3 justify-evenly mt-4 lg:flex-row flex-col lg:items-start">
          <ol className="list-decimal text-lg font-semibold">
            <li>QR Menu</li>
            <li>POS Integration</li>
            <li>Unlimited Orders</li>
            <li>Unlimited Menu Items</li>
            <li>Seamless Dining Experience</li>
            <li>Intuitive Ordering Process</li>
            <li>Real-Time Order Transmission</li>
            <li>Streamlined Kitchen Workflow</li>
            <li>Automatic Bill Generation</li>
            <li>Secure Payment Options</li>
          </ol>
          <ol className="list-decimal text-lg font-semibold " start={11}>
            <li>Order Tracking</li>
            <li>Feedback Mechanism</li>
            <li>Customizable User Roles</li>
            <li>Enhanced Security Measures</li>
            <li>Flexible Subscription Plans</li>
            <li>Comprehensive Admin Controls</li>
            <li>Staff Empowerment</li>
            <li>User-Friendly Interface</li>
            <li>Continuous Support and Updates</li>
            <li>Free setup</li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default Subscriptions;
