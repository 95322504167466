import React, { useState, useMemo, useEffect } from "react";
import {
  Box,
  Container,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  Text,
  PinInput,
  PinInputField,
} from "@chakra-ui/react";
import { toast } from "react-hot-toast";
import { Logo } from "./Logo";
import imageConstant from "../../constants/imageConstant";
import { PasswordField } from "./PasswordField";
import { useDispatch } from "react-redux";
import { login as loginReducer } from "../../redux/authSlice";
import Loader from "../../UI/Loader";
import { sendOtp, verifyOtp, register } from "../../services/userApi";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import MyButton from "../../UI/MyButton";
import { getErrorMessage } from "../../utils/functions";
export const SignUp = () => {
  const otpResendTime = 5; //seconds
  const navigate = useNavigate();
  const { auth } = useSelector((state) => state);
  const { isAuth } = auth;

  const [loading, setLoading] = useState(false); // [1
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState({
    firstName: "",
    lastName: "",
  });
  const dispatch = useDispatch();
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");

  const [showSendOtpButton, setShowSendOtpButton] = useState(false);

  const [isNumberVerified, setIsNumberVerified] = useState(false);
  const [isSendOtpButtonDisabled, setIsSendOtpButtonDisabled] = useState(false);
  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      if (
        !name.firstName ||
        !name.lastName ||
        !email ||
        !password ||
        !confirmPassword ||
        !phone
      )
        return toast.error("Please fill all fields");
      //password length should be greater than 6
      if (password.length < 6)
        return toast.error("Password length should be greater than 6");

      if (password !== confirmPassword)
        return toast.error("Password and confirm password should be same");
      //check whether email is valid or not
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email))
        return toast.error("Please enter valid email address");
      //if phone contain + then toast
      if (phone.includes("+"))
        return toast.error("Please enter phone without country code");
      //check whether phone number is valid or not
      const phoneRegex = /^[0-9]{10}$/;
      if (!phoneRegex.test(phone))
        return toast.error("Please enter valid phone number");

      // if (!isNumberVerified)
      //   return toast.error('Please verify your phone number first');
      setLoading(true);
      const res = await register({
        name: `${name.firstName} ${name.lastName}`,
        email,
        password,
        mobileNumber: phone,
        confirmPassword,
      });

      dispatch(
        loginReducer({
          isAuth: true,
          // isAdmin: res.data.isAdmin,
          token: res.accessToken,
          user: res.user,
          // classes: res.data.classes,
        })
      );
      toast.success("Signup successful!");
      navigate("/login");
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (isNumberVerified) {
      setShowSendOtpButton(false);
      setShowOtpInput(false);
      setOtp("");
    }
  }, [isNumberVerified]);
  const handleVerifyOtp = async () => {
    try {
      if (!otp) return toast.error("Please enter otp");
      if (!phone) return toast.error("Please enter phone number");
      if (!otp.length === 4) return toast.error("Please enter valid otp");
      setLoading(true);
      const res = await verifyOtp({ phone, otp });
      toast.success(res.message);
      toast.success("Click On SignUp");
      setIsNumberVerified(true);
      setLoading(false);
    } catch (error) {
      if (error?.response?.data?.message)
        toast.error(error?.response?.data?.message);
      else toast.error(error?.message);
      setLoading(false);
    }
  };
  const handleSendOtp = async () => {
    try {
      setShowOtpInput(true);
      setIsSendOtpButtonDisabled(true);
      if (!phone) return toast.error("Please enter phone number");
      if (phone.length !== 10)
        return toast.error("Please enter valid phone number");
      setTimeout(() => {
        setIsSendOtpButtonDisabled(false);
      }, otpResendTime * 1000);
      setLoading(true);
      const res = await sendOtp({ phone });
      setLoading(false);

      toast.success(res.message);
      toast.success(res.otp);

      //enable send otp MyButton   after 60 seconds
    } catch (error) {
      if (error?.response?.data?.message)
        toast.error(error?.response?.data?.message);
      else toast.error(error?.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    //if phone number length is 10 then show send otp MyButton
    if (phone.length === 10) setShowSendOtpButton(true);
    else setShowSendOtpButton(false);
  }, [phone]);
  if (loading) return <Loader />;
  return (
    <div className="flex">
      <div className="w-1/2 h-screen hidden lg:flex bg-tertiary flex-col justify-center items-center text-center object-cover">
        <img
          src={imageConstant.signup}
          className="w-full h-[100vh] object-cover "
          alt="login"
        />
      </div>
      <Container
        maxW="lg"
        px={{
          base: "0",
          sm: "8",
        }}
      >
        <Stack spacing="2">
          <Stack spacing="4">
            <div className="flex justify-center">
              <Logo />
            </div>
            <Stack textAlign="center">
              <Heading
                size={{
                  base: "xs",
                  md: "sm",
                }}
              >
                Sign Up
              </Heading>
            </Stack>
          </Stack>
          <Box
            py={{
              base: "0",
              sm: "8",
            }}
            px={{
              base: "4",
              sm: "10",
            }}
            bg={{
              base: "transparent",
              sm: "bg-surface",
            }}
            boxShadow={{
              base: "none",
              sm: "md",
            }}
            borderRadius={{
              base: "none",
              sm: "xl",
            }}
          >
            <Stack spacing="6" as="form" onSubmit={handleSignUp}>
              <Stack spacing="5">
                <FormControl>
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <Input
                    id="email"
                    type="email"
                    value={email}
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="firstName">First Name</FormLabel>
                  <Input
                    id="firstName"
                    type="text"
                    value={name.firstName}
                    name="firstName"
                    placeholder="Name"
                    onChange={(e) =>
                      setName({
                        ...name,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="lastName">Last Name</FormLabel>
                  <Input
                    id="name"
                    type="text"
                    value={name.lastName}
                    name="lastName"
                    placeholder="Name"
                    onChange={(e) =>
                      setName({
                        ...name,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </FormControl>
                <PasswordField
                  value={password}
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <PasswordField
                  label="Confirm Password"
                  value={confirmPassword}
                  placeholder="Confirm Password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />

                <FormControl>
                  <FormLabel htmlFor="phone">Phone</FormLabel>
                  <Input
                    id="phone"
                    type="text"
                    value={phone}
                    //if number is verified then disable phone input
                    isDisabled={isNumberVerified || isSendOtpButtonDisabled}
                    placeholder="Phone"
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </FormControl>
                {showSendOtpButton && !isNumberVerified && (
                  <MyButton
                    variant="primary"
                    // className="bg-primary text-white"
                    className={
                      isSendOtpButtonDisabled
                        ? "bg-gray-400 text-white"
                        : "bg-blue-400 text-white"
                    }
                    isDisabled={isSendOtpButtonDisabled}
                    //stop hover effect when MyButton is disabled
                    _hover={
                      isSendOtpButtonDisabled
                        ? { bg: "gray.400" }
                        : { bg: "primary" }
                    }
                    onClick={handleSendOtp}
                  >
                    Send OTP
                  </MyButton>
                )}
                {isSendOtpButtonDisabled && (
                  <Text fontSize="xs" color="muted">
                    You can resend OTP after {otpResendTime} seconds
                  </Text>
                )}
                {showOtpInput && (
                  <FormControl>
                    <FormLabel htmlFor="otp">OTP</FormLabel>
                    <HStack>
                      <PinInput otp onChange={(e) => setOtp(e)}>
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                      </PinInput>
                      <MyButton
                        variant="primary"
                        className="bg-blue-400 text-white"
                        onClick={handleVerifyOtp}
                      >
                        Verify OTP
                      </MyButton>
                    </HStack>
                  </FormControl>
                )}
              </Stack>
              <HStack justify="space-between">
                {/* <Checkbox defaultChecked>Remember me</Checkbox> */}
              </HStack>
              <Stack spacing="6">
                <MyButton
                  variant="primary"
                  className="bg-primary text-white"
                  type="submit"
                  bypassRoleCheck
                >
                  Sign Up
                </MyButton>
                {isNumberVerified ? null : (
                  <MyButton
                    variant="link"
                    colorScheme="blue"
                    size="sm"
                    onClick={() => navigate("/login")}
                    bypassRoleCheck
                  >
                    Already have an account? Login here
                  </MyButton>
                )}
                {/* <HStack>
                <Divider />
                <Text fontSize="sm" whiteSpace="nowrap" color="muted">
                  or continue with
                </Text>
                <Divider />
              </HStack> */}
                {/* <OAuthButtonGroup /> */}
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Container>
    </div>
  );
};

export default SignUp;
