import {
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  PinInput,
  PinInputField,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { PasswordField } from "../../components/Login/PasswordField";
import { forgotPasswordSendOtp, verifyOtp } from "../../services/userApi";
import MyButton from "../MyButton";

function ForgotPassword() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [phone, setPhone] = useState("");
  const [showSendOtpButton, setShowSendOtpButton] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  // const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  // useEffect(() => {
  //   if (phone.length > 0) setShowSendOtpButton(true);
  //   else setShowSendOtpButton(false);
  // }, [phone]);

  const handleSendOtp = async () => {
    try {
      if (!phone) return toast.error("Please enter phone number");
      // if (phone.length !== 10)
      //   return toast.error("Please enter valid phone number");
      setLoading(true);
      await forgotPasswordSendOtp({ type: "mobile", value: phone });
      setIsOtpSent(true);
      setLoading(false);

      toast.success("Otp Sent");

      //enable send otp MyButton   after 60 seconds
    } catch (error) {
      setIsOtpSent(false);
      if (error?.response?.data?.message)
        toast.error(error?.response?.data?.message);
      else toast.error(error?.message);
      setLoading(false);
    }
  };

  const handleVerifyOtp = async () => {
    try {
      if (!otp) return toast.error("Please enter OTP");
      if (!phone) return toast.error("Please enter phone number");
      if (otp.length !== 4) return toast.error("Please enter a valid OTP");
      if (!newPassword) return toast.error("Please enter password");

      setLoading(true);

      const phoneValue = phone; // Extract the value to a separate variable
      const otpValue = otp; // Extract the value to a separate variable

      await verifyOtp({
        mobileNumber: phoneValue,
        otp: otpValue,
        type: "mobile",
        newPassword,
      });

      toast.success("Password Changed");
      setIsOtpVerified(true);
      setLoading(false);
      handleModalClose();
    } catch (error) {
      setIsOtpVerified(false);

      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error(error?.message);
      }

      setLoading(false);
    }
  };

  // const handleUpdatePassword = async () => {
  //   // Logic to update the password
  //   // You can implement your own API call or integration here

  //   // For demonstration purposes, we'll check if the new password and confirm password match
  //   try {
  //     if (newPassword === confirmPassword) {
  //       const resp = await updatePassword({ phone, password: newPassword });
  //       toast.success(resp.message);
  //       onClose();
  //     } else {
  //       toast.error("Passwords do not match");
  //     }
  //   } catch (error) {
  //     toast.error(error?.message);
  //   }
  // };

  const handleModalClose = () => {
    // Reset all the states to their initial values
    setPhone("");
    setShowSendOtpButton(false);
    setIsOtpSent(false);
    setOtp("");
    setIsOtpVerified(false);
    setNewPassword("");
    onClose();
  };

  return (
    <>
      <MyButton
        variant="link"
        colorScheme="blue"
        size="sm"
        onClick={onOpen}
        bypassRoleCheck
      >
        Forgot Password
      </MyButton>
      <Modal isOpen={isOpen} onClose={handleModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Forgot Password?</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Enter your Email</FormLabel>
              <Input
                placeholder="Enter email"
                type="email"
                onChange={(e) => setPhone(e.target.value)}
                value={phone}
                disabled={isOtpSent}
              />
            </FormControl>

            {!isOtpSent && (
              <div className="w-full flex justify-center items-center mt-3">
                <MyButton
                  variant="primary"
                  colorScheme="blue"
                  onClick={handleSendOtp}
                  bypassRoleCheck
                  className="bg-blue-400 text-white mt-2"
                  loading={loading}
                >
                  Send OTP
                </MyButton>
              </div>
            )}

            {isOtpSent && (
              <FormControl>
                <FormLabel htmlFor="otp">OTP</FormLabel>
                <HStack className="mb-2">
                  <PinInput otp onChange={(e) => setOtp(e)}>
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                  </PinInput>
                </HStack>
                <PasswordField
                  label="New Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />

                <div className="w-full flex flex-col justify-center items-center mt-3">
                  <MyButton
                    variant="primary"
                    className="bg-blue-400 text-white mt-2"
                    onClick={handleVerifyOtp}
                    bypassRoleCheck
                    loading={loading}
                  >
                    Verify OTP
                  </MyButton>
                </div>
              </FormControl>
            )}

            {/* {isOtpVerified && (
              <FormControl mt={4}>
                <PasswordField
                  label="New Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <PasswordField
                  label="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </FormControl>
            )} */}
          </ModalBody>
          {/* 
          <ModalFooter>
            {isOtpVerified ? (
              <MyButton
                colorScheme="blue"
                mr={3}
                onClick={handleUpdatePassword}
                bypassRoleCheck
              >
                Update Password
              </MyButton>
            ) : (
              <MyButton onClick={handleModalClose} bypassRoleCheck>
                Cancel
              </MyButton>
            )}
          </ModalFooter> */}
        </ModalContent>
      </Modal>
    </>
  );
}

export default ForgotPassword;
