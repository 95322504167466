import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { ROLES } from "../../constants/constant";
import VerificationPending from "./components/Contact";
import VerificationForm from "./components/VerificationForm";

const Verification = () => {
  const navigation = useNavigate();
  const { auth } = useSelector((state) => state);
  const { user } = auth;

  useEffect(() => {
    if (
      user?.restaurant?.isFreeTrial ||
      user?.restaurant?.isSubscriptionActive
    ) {
      navigation("/dashboard");
      return;
    }
  }, [user]);

  if (user?.role === ROLES.EMPLOYEE) {
    return (
      <div>
        <div>
          <h1>You don't have permission to access this page</h1>
        </div>
      </div>
    );
  }

  if (!user?.restaurant) {
    return <VerificationForm />;
  }

  if (!user?.restaurant?.isVerified) {
    return <VerificationPending />;
  }

  if (
    !user?.restaurant?.isFreeTrial ||
    !user?.restaurant?.isSubscriptionActive
  ) {
    return <Navigate to="/subscription" />;
  }
};

export default Verification;
