import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  variants: [],
  categories: [],
  quantityUnits: [],
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setVariants(state, action) {
      state.variants = action.payload;
    },

    setCategories(state, action) {
      state.categories = action.payload;
    },

    setQuantityUnits(state, action) {
      state.quantityUnits = action.payload;
    },

    addReduxVariant(state, action) {
      state.variants = state.variants.concat(action.payload).flat();
    },

    addReduxCategory(state, action) {
      state.categories.push(action.payload);
    },

    addReduxQuantityUnit(state, action) {
      state.quantityUnits = state.quantityUnits.concat(action.payload).flat();
    },

    deleteReduxVariant(state, action) {
      state.variants = state.variants.filter(
        (variant) => variant._id !== action.payload
      );
    },

    deleteReduxCategory(state, action) {
      state.categories = state.categories.filter(
        (category) => category._id !== action.payload
      );
    },

    deleteReduxQuantityUnit(state, action) {
      state.quantityUnits = state.quantityUnits.filter(
        (unit) => unit._id !== action.payload
      );
    },
  },
});

export const {
  setVariants,
  setCategories,
  setQuantityUnits,
  addReduxVariant,
  addReduxCategory,
  addReduxQuantityUnit,
  deleteReduxVariant,
  deleteReduxCategory,
  deleteReduxQuantityUnit,
} = settingsSlice.actions;

export default settingsSlice.reducer;
