import React, { useEffect, useState } from "react";
import { FaTrash } from "react-icons/fa";
import InputField from "../../../UI/InputField";
import { ROLES, restaurantFields } from "../../../constants/constant";
import MyButton from "../../../UI/MyButton";

import toast from "react-hot-toast";

import { City } from "country-state-city";

const RestaurantForm = ({
  restaurant,
  images,
  loading,
  imagePreviews,
  handleImageUpload,
  removeImage,
  handleInputChange,
  setShowRestaurant,
  handleSelectChange,
  addRestaurant,
  isRestaurantEdit,
}) => {
  const [restaurantAdmins, setRestaurantAdmins] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    if (isRestaurantEdit && restaurant?.address?.state)
      setCities(City.getCitiesOfState("IN", restaurant.address.state));
  }, [restaurant, isRestaurantEdit]);


  const getAddressState = restaurant?.address?.state || restaurant?.state;

  const cityOptions =
    !isRestaurantEdit && getAddressState
      ? City.getCitiesOfState("IN", getAddressState).map((item) => ({
          label: item.name,
          value: item.name,
        }))
      : cities.map((item) => ({
          label: item.name,
          value: item.name,
        }));

  return (
    <div className="ml-7 rounded-2xl p-3">
      <h1 className="text-3xl text-center font-semibold mb-3">
        Restaurant Details
      </h1>
      <form
        className="flex flex-col lg:flex-row lg:space-x-8"
        onSubmit={addRestaurant}
      >
        <div className="lg:w-1/2">
          <div className="flex gap-4 flex-col">
            {restaurantFields?.slice(0, 2).map((field, index) => (
              <div key={index}>
                <InputField
                  labelName={field.label}
                  inputClass={
                    "h-10 bg-[#F6F1EE] rounded-lg  border border-black border-opacity-20"
                  }
                  isRequired={field.isRequired && !isRestaurantEdit}
                  type={field.type}
                  name={field.name}
                  value={restaurant[field.name]}
                  options={field.options}
                  labelClass={"text-black  mb-1 font-semibold"}
                  info={field.info}
                  infoTitle={field.infoTitle}
                  onChange={
                    field.type === "select"
                      ? (selectedValue) =>
                          handleSelectChange(field.name, selectedValue)
                      : (e) => handleInputChange(field.name, e.target.value)
                  }
                />
              </div>
            ))}
            {!isRestaurantEdit && (
              <div>
                <InputField
                  labelName={"Owner"}
                  inputClass={
                    "h-10 bg-[#F6F1EE] rounded-lg  border border-black border-opacity-20"
                  }
                  type={"select"}
                  name={"owner"}
                  value={restaurant["owner"]}
                  options={restaurantAdmins.map((admin) => {
                    return {
                      label: admin.name,
                      value: admin._id,
                    };
                  })}
                  labelClass={"text-black  mb-1 font-semibold"}
                  isRequired
                  onChange={(selectedValue) =>
                    handleSelectChange("owner", selectedValue)
                  }
                />
              </div>
            )}
          </div>

          <div className="mt-2 z-0">
            {/* <Gap>Add Images</Gap> */}
            <div className="flex justify-center gap-4 p-3 -2xl flex-col z-0">
              <InputField
                id="logo"
                hidden
                name={"logo"}
                type="file"
                onChange={handleImageUpload}
                accept="image/png, image/jpeg"
                labelName={"Logo"}
                labelClass={"text-black  mb-1 font-semibold"}
                isRequired={!isRestaurantEdit}
                // multiple
              />
              <div className="flex justify-evenly flex-wrap">
                {imagePreviews.map((preview, index) => (
                  <div key={index} className="relative flex gap-3">
                    <img
                      key={index}
                      src={preview}
                      alt={`preview-${index}`}
                      className="aspect-square w-[200px] mt-2 lg:mt-0"
                    />
                    <MyButton
                      className="absolute top-0 right-0 p-1 bg-red-500 text-white rounded-full"
                      onClick={() => removeImage(index)}
                      type="button"
                      allowedRoles={[ROLES.SUPER_ADMIN, ROLES.RESTAURANT_ADMIN]}
                    >
                      <FaTrash />
                    </MyButton>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Right Side - Second Half */}
        <div className="lg:w-1/2">
          {/* <Gap>Remaining Menu Item Details</Gap> */}
          <div className="flex gap-4 flex-col">
            {restaurantFields?.slice(2, 4).map((field, index) => (
              <InputField
                key={index}
                labelName={field.label}
                inputClass={
                  "h-10 bg-[#F6F1EE] rounded-lg  border border-black border-opacity-20"
                }
                type={field.type}
                name={field.name}
                value={
                  restaurant.address &&
                  restaurant.address.hasOwnProperty(field.name)
                    ? restaurant.address[field.name]
                    : restaurant[field.name]
                }
                options={field.options}
                labelClass={"text-black mb-1 font-semibold"}
                onChange={
                  field.type === "select"
                    ? (selectedValue) =>
                        handleSelectChange(field.name, selectedValue)
                    : (e) => handleInputChange(field.name, e.target.value)
                }
                info={field.info}
                infoTitle={field.infoTitle}
                isRequired={field.isRequired && !isRestaurantEdit}
              />
            ))}
            <InputField
              label="City"
              type="select"
              name="city"
              options={cityOptions}
              // info={}
              // infoTitle={field.infoTitle}
              isRequired={!isRestaurantEdit}
              labelName={"City"}
              inputClass={
                "h-10 bg-[#F6F1EE] rounded-lg  border border-black border-opacity-20"
              }
              value={restaurant?.address?.city || restaurant.city}
              labelClass={"text-black mb-1 font-semibold"}
              onChange={(selectedValue) =>
                handleSelectChange("city", selectedValue)
              }
            />
            {restaurantFields
              .slice(4, restaurantFields.length)
              .map((field, index) => (
                <InputField
                  key={index}
                  labelName={field.label}
                  isRequired={field.isRequired && !isRestaurantEdit}
                  inputClass={
                    "h-10 bg-[#F6F1EE] rounded-lg  border border-black border-opacity-20"
                  }
                  type={field.type}
                  name={field.name}
                  value={
                    field.name === "city" ||
                    field.name === "state" ||
                    field.name === "street" ||
                    field.name === "postalCode"
                      ? restaurant?.address?.[field.name]
                      : restaurant[field.name]
                  }
                  options={field.options}
                  labelClass={"text-black mb-1 font-semibold"}
                  onChange={
                    field.type === "select"
                      ? (selectedValue) =>
                          handleSelectChange(field.name, selectedValue)
                      : (e) => handleInputChange(field.name, e.target.value)
                  }
                  info={field.info}
                  infoTitle={field.infoTitle}
                />
              ))}

            <MyButton
              className="bg-primary text-white rounded-xl p-2"
              type="submit"
              loading={loading}
              allowedRoles={[ROLES.SUPER_ADMIN, ROLES.RESTAURANT_ADMIN]}
            >
              {isRestaurantEdit ? "Update" : "Submit"}
            </MyButton>
          </div>
        </div>
      </form>
    </div>
  );
};

export default RestaurantForm;
