import React, { useEffect, useState, useRef } from "react";
import Gap from "../../UI/Gap";
import InputField from "../../UI/InputField";
import MyButton from "../../UI/MyButton";
import { Plus } from "mdi-material-ui";
import {
  addVariant as createVariant,
  deleteVariant as removeVariant,
  addCategory as createCategory,
  deleteCategory as removeCategory,
  addQuantityUnit as createQuantityUnit,
  deleteQuantityUnit as removeQuantityUnit,
} from "../../services/settingsApi";
import toast from "react-hot-toast";
import { getErrorMessage } from "../../utils/functions";
import { FaImage } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  addReduxCategory,
  addReduxQuantityUnit,
  addReduxVariant,
  deleteReduxCategory,
  deleteReduxQuantityUnit,
  deleteReduxVariant,
} from "../../redux/settingsSlice";
import { Avatar, Tag, TagCloseButton, TagLabel } from "@chakra-ui/react";
import ConfirmationModal from "../../UI/ConfirmationModal";

const Settings = () => {
  const [newVariant, setNewVariant] = useState("");
  const [newCategory, setNewCategory] = useState("");
  const [newQuantityUnit, setNewQuantityUnit] = useState("");
  const [categoryImage, setCategoryImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [loadingVariants, setLoadingVariants] = useState(false);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [loadingQuantityUnits, setLoadingQuantityUnits] = useState(false);
  const [quantityUnitImagePreview, setQuantityUnitImagePreview] =
    useState(null);
  const [isOpenDeleteVariant, setIsOpenDeleteVariant] = useState(false);
  const [isOpenDeleteCategory, setIsOpenDeleteCategory] = useState(false);
  const [isOpenDeleteQuantityUnit, setIsOpenDeleteQuantityUnit] =
    useState(false);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedQuantityUnit, setSelectedQuantityUnit] = useState(null);

  const handleDeleteVariant = (variant) => {
    setSelectedVariant(variant);
    setIsOpenDeleteVariant(true);
  };

  const handleCloseDeleteVariant = () => {
    setIsOpenDeleteVariant(false);
    setSelectedVariant(null);
  };

  const handleDeleteCategory = (category) => {
    setSelectedCategory(category);
    setIsOpenDeleteCategory(true);
  };

  const handleCloseDeleteCategory = () => {
    setIsOpenDeleteCategory(false);
    setSelectedCategory(null);
  };

  const handleDeleteQuantityUnit = (quantityUnit) => {
    setSelectedQuantityUnit(quantityUnit);
    setIsOpenDeleteQuantityUnit(true);
  };

  const handleCloseDeleteQuantityUnit = () => {
    setIsOpenDeleteQuantityUnit(false);
    setSelectedQuantityUnit(null);
  };

  const categoryFileInputRef = useRef(null);

  const settings = useSelector((state) => state.settings);
  const variants = settings.variants;
  const categories = settings.categories;
  const quantityUnits = settings.quantityUnits;

  const dispatch = useDispatch();

  const handleNewVariantChange = (event) => {
    setNewVariant(event.target.value);
  };

  const handleNewCategoryChange = (event) => {
    setNewCategory(event.target.value);
  };

  const handleNewQuantityUnitChange = (event) => {
    setNewQuantityUnit(event.target.value);
  };

  const handleCategoryImageChange = (event) => {
    const selectedImage = event.target.files[0];
    if (selectedImage) {
      setCategoryImage(selectedImage);
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(selectedImage);
    }
  };

  const handleChooseCategoryImageClick = () => {
    categoryFileInputRef.current.click();
  };

  const addVariant = async () => {
    try {
      setLoadingVariants(true);
      if (newVariant.trim() !== "") {
        const res = await createVariant({
          name: newVariant,
        });
        dispatch(addReduxVariant(res));
        setNewVariant("");
      }
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoadingVariants(false);
    }
  };

  const addCategory = async () => {
    try {
      setLoadingCategories(true);
      if (newCategory.trim() !== "" && categoryImage !== null) {
        const formData = new FormData();
        formData.append("name", newCategory);
        formData.append("thumbnail", categoryImage);
        const res = await createCategory(formData);
        dispatch(addReduxCategory(res));
        setNewCategory("");
        setCategoryImage(null);
        setImagePreview(null);
      } else {
        toast.error("Category name and image are required");
      }
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoadingCategories(false);
    }
  };

  const addQuantityUnit = async () => {
    try {
      setLoadingQuantityUnits(true);
      if (newQuantityUnit.trim() !== "") {
        const res = await createQuantityUnit({
          name: newQuantityUnit,
        });
        dispatch(addReduxQuantityUnit(res));
        setNewQuantityUnit("");

        setQuantityUnitImagePreview(null);
      } else {
        toast.error("Quantity unit name is required");
      }
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoadingQuantityUnits(false);
    }
  };

  const deleteVariant = async () => {
    try {
      await removeVariant(`?menuId=${selectedVariant._id}`);
      dispatch(deleteReduxVariant(selectedVariant._id));
      setIsOpenDeleteVariant(false);
      setSelectedVariant(null);
    } catch (error) {
      toast.error(getErrorMessage(error));
    }
  };

  const deleteCategory = async () => {
    try {
      await removeCategory(`?menuId=${selectedCategory._id}`);
      dispatch(deleteReduxCategory(selectedCategory._id));
      setIsOpenDeleteCategory(false);
      setSelectedCategory(null);
    } catch (error) {
      toast.error(getErrorMessage(error));
    }
  };

  const deleteQuantityUnit = async () => {
    try {
      await removeQuantityUnit(`?unitId=${selectedQuantityUnit._id}`);
      dispatch(deleteReduxQuantityUnit(selectedQuantityUnit._id));
      setIsOpenDeleteQuantityUnit(false);
      setSelectedQuantityUnit(null);
    } catch (error) {
      toast.error(getErrorMessage(error));
    }
  };

  return (
    <div className="flex justify-start p-2 lg:flex-row flex-col gap-2 overflow-hidden">
      <ConfirmationModal
        isOpen={isOpenDeleteVariant}
        onClose={handleCloseDeleteVariant}
        onConfirm={deleteVariant}
        loading={loadingVariants}
        message="Are you sure you want to delete this variant?"
      />
      <ConfirmationModal
        isOpen={isOpenDeleteCategory}
        onClose={handleCloseDeleteCategory}
        onConfirm={deleteCategory}
        loading={loadingCategories}
        message="Are you sure you want to delete this category?"
      />
      <ConfirmationModal
        isOpen={isOpenDeleteQuantityUnit}
        onClose={handleCloseDeleteQuantityUnit}
        onConfirm={deleteQuantityUnit}
        loading={loadingQuantityUnits}
        message="Are you sure you want to delete this quantity unit?"
      />
      <div className="p-2 border border-[#b6b6b6] rounded shadow-xl lg:w-1/3 bg-orange-100">
        <Gap>
          <h1 className="text-xl"> Variants</h1>
        </Gap>
        <div className="flex w-full gap-2 items-center justify-start lg:flex-row md:flex-row flex-col">
          <InputField
            labelClass={"font-bold"}
            value={newVariant}
            onChange={handleNewVariantChange}
            info={true}
            infoTitle={
              "Add multiple items at once by separating them with commas. For example, 'veg, non-veg, egg'."
            }
          />
          <MyButton
            className="p-2 text-white rounded-[10px] bg-slate-800 font-semibold flex justify-center items-center gap-1"
            variant="primary"
            colorScheme="blue"
            size="sm"
            bypassRoleCheck
            onClick={addVariant}
            loading={loadingVariants}
          >
            <Plus />
            Add
          </MyButton>
        </div>
        <ul className="flex flex-wrap gap-5 mt-3">
          {variants.map((variant, index) => (
            <li key={index} className="flex items-center gap-1 ">
              <Tag size={"md"} borderRadius="full" variant="subtle">
                <TagLabel className="pl-2 pt-1 pb-1">{variant.name}</TagLabel>
                <MyButton
                  className="text-red-600"
                  onClick={() => handleDeleteVariant(variant)}
                  bypassRoleCheck
                >
                  <TagCloseButton color="red" size={24} />
                </MyButton>
              </Tag>
            </li>
          ))}
        </ul>
      </div>

      <div className="p-2 border rounded border-[#b6b6b6] shadow-xl lg:w-1/3 bg-orange-100">
        <Gap>
          <h1 className="text-xl">Categories </h1>
        </Gap>
        <div className="flex w-full gap-2 items-center justify-start  lg:flex-row md:flex-row flex-col">
          <InputField
            labelClass={"font-bold"}
            value={newCategory}
            onChange={handleNewCategoryChange}
          />
          {imagePreview && (
            <img
              src={imagePreview}
              alt="Category Preview"
              className="mt-2 w-10 h-10 object-cover border border-black"
            />
          )}
          <input
            type="file"
            accept="image/*"
            onChange={handleCategoryImageChange}
            className="hidden"
            ref={categoryFileInputRef}
            id="categoryImageInput"
          />
          <MyButton
            className="pl-2 pr-2 p-1 text-white rounded-[10px] bg-blue-800 font-semibold flex justify-center items-center gap-1 h-10"
            variant="primary"
            colorScheme="blue"
            size="sm"
            loading={loadingCategories}
            bypassRoleCheck
            onClick={handleChooseCategoryImageClick}
          >
            <FaImage />
            {/* Choose Image */}
          </MyButton>
          <MyButton
            className="pl-2 pr-2 p-1 text-white rounded-[10px] bg-slate-800 font-semibold flex justify-center items-center gap-1 h-10"
            variant="primary"
            colorScheme="blue"
            size="sm"
            bypassRoleCheck
            onClick={addCategory}
            loading={loadingCategories}
          >
            <Plus />
            Add
          </MyButton>
        </div>

        <ul className="flex flex-wrap gap-5 mt-3">
          {categories.map((category, index) => (
            <li key={index} className="flex items-center gap-1 ">
              <Tag size={"md"} borderRadius="full" variant="subtle">
                <Avatar src={category.thumbnail} size={"sm"} />
                <TagLabel className="pl-2 pt-1 pb-1">{category.name}</TagLabel>
                <MyButton
                  className="text-red-600"
                  onClick={() => handleDeleteCategory(category)}
                  bypassRoleCheck
                >
                  <TagCloseButton color="red" size={24} />
                </MyButton>
              </Tag>
            </li>
          ))}
        </ul>
      </div>

      <div className="p-2 border rounded border-[#b6b6b6] shadow-xl lg:w-1/3 bg-orange-100">
        <Gap>
          <h1 className="text-xl">Quantity Units</h1>
        </Gap>
        <div className="flex w-full gap-2 items-center justify-start  lg:flex-row md:flex-row flex-col">
          <InputField
            // labelName={"Quantity Unit Name"}
            labelClass={"font-bold"}
            info={true}
            infoTitle={
              "Add multiple items at once by separating them with commas. For example, 'veg, non-veg, egg'."
            }
            value={newQuantityUnit}
            onChange={handleNewQuantityUnitChange}
          />

          <MyButton
            className="pl-2 pr-2 p-1 text-white rounded-[10px] bg-slate-800 font-semibold flex justify-center items-center gap-1 h-10"
            variant="primary"
            colorScheme="blue"
            size="sm"
            bypassRoleCheck
            onClick={addQuantityUnit}
            loading={loadingQuantityUnits}
          >
            <Plus />
            Add
          </MyButton>
        </div>
        {quantityUnitImagePreview && (
          <img
            src={quantityUnitImagePreview}
            alt="Quantity Unit Preview"
            className="mt-2 w-20 h-20 object-cover"
          />
        )}
        <ul className="flex flex-wrap gap-5 mt-3">
          {quantityUnits.map((unit, index) => (
            <li key={index} className="flex items-center gap-1 ">
              <Tag size={"md"} borderRadius="full" variant="subtle">
                <TagLabel className="pl-2 pt-1 pb-1">{unit.name}</TagLabel>
                <MyButton
                  className="text-red-600"
                  onClick={() => handleDeleteQuantityUnit(unit)}
                  bypassRoleCheck
                >
                  <TagCloseButton color="red" size={24} />
                </MyButton>
              </Tag>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Settings;
