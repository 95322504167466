import http from "./http";

const tableApi = "/restaurant/table";

export const createTable = (data, query = "") => {
  return http.post(`${tableApi}/create${query}`, data);
};

export const getAllTable = async () => {
  const res = await http.get(`${tableApi}/all`);
  return res;
};
export const getTableStats = async () => {
  const res = await http.get(`${tableApi}/stats`);
  return res;
};

export const deleteTable = async (params) => {
  return http.delete(`${tableApi}/delete/${params}`);
};

export const updateTable = async (data, params) => {
  return http.put(`${tableApi}/update/${params}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const createBulkTable = async (data) => {
  return http.post(`${tableApi}/create/bulk`, data);
};
