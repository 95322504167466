import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import ConfirmationModal from "../../../UI/ConfirmationModal";
import { getErrorMessage } from "../../../utils/functions";
import DepartmentCard from "./DepartmentCard";
import { getAllEmployees, deleteEmployee } from "../../../services/employeeApi";
import EmployeeTable from "./EmployeeTable";
import { useSelector } from "react-redux";

const EmployeeList = ({ setTotalEmployees, setShowEmployee }) => {
  const { user } = useSelector((state) => state.auth);

  const [employees, setEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [employee, setEmployee] = useState({});
  const [open, setOpen] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllEmployees()
      .then((res) => {
        setEmployees(res);
        setFilteredEmployees(res);
        setTotalEmployees(res.length);
      })
      .catch((err) => toast.error(err.message));
  }, []);

  const showConfirmationModal = (employee) => {
    setOpen(true);
    setEmployee(employee);
  };

  const handleDeleteEmployee = async () => {
    try {
      setLoading(true);
      if (employee._id === user._id)
        return toast.error("You cann't delete your own account");
      await deleteEmployee(`${employee._id}`);
      setEmployees((prevEmployees) =>
        prevEmployees.filter((emp) => emp._id !== employee._id)
      );
      setFilteredEmployees((prevEmployees) =>
        prevEmployees.filter((emp) => emp._id !== employee._id)
      );
      toast.success("Employee deleted successfully!");
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  const groupedEmployees = employees?.reduce((acc, employee) => {
    const department = employee.department;
    acc[department] = acc[department] || [];
    acc[department].push(employee);
    return acc;
  }, {});

  const handleCategoryClick = (department) => {
    setSelectedDepartment(
      department === selectedDepartment ? null : department
    );
    // filterItemsByCategory(department);
  };

  useEffect(() => {
    const filteredEmployees = selectedDepartment
      ? employees.filter(
          (employee) => employee.department === selectedDepartment
        )
      : employees;
    setFilteredEmployees(filteredEmployees);
  }, [selectedDepartment, employees]);
  return (
    <div>
      <ConfirmationModal
        isOpen={open}
        message={"Are you sure you want to delete this employee?"}
        onClose={() => setOpen(false)}
        onConfirm={handleDeleteEmployee}
        loading={loading}
      />
      <div className="flex lg:ml-[1.2rem] p-2 gap-3 overflow-x-auto no-scrollbar">
        <div className="flex gap-4">
          {Object.entries(groupedEmployees).map(
            ([department, employeeDepartments], index) => (
              <DepartmentCard
                key={index}
                department={department}
                totalEmployeesInDepartment={employeeDepartments}
                onClick={() => handleCategoryClick(department)}
                isActive={department === selectedDepartment}
              />
            )
          )}
        </div>
      </div>
      <div className="p-3">
        <EmployeeTable
          employees={filteredEmployees}
          showConfirmationModal={showConfirmationModal}
          setShowEmployee={setShowEmployee}
          setEmployees={setEmployees}
        />
      </div>
    </div>
  );
};

export default EmployeeList;
