import {
  Box,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { State } from "country-state-city";
import moment from "moment";
import React from "react";
import { splitFieldName } from "../../../utils/functions";

const excludeFieldsInModal = [
  "_id",
  "__v",
  "updatedAt",
  "owner",
  "password",
  "provider",
  "restaurant",
  "createdAt",
];

const ViewEmployeeModal = ({ isOpen, onClose, title, details }) => {
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <table className="table-auto w-full">
            <tbody>
              {/* Iterate over details */}
              {Object.entries(details || {}).map(
                ([key, value]) =>
                  !excludeFieldsInModal.includes(key) && (
                    <tr key={key}>
                      <td className="py-2 px-4 border border-gray-300">
                        <strong>{splitFieldName(key)}</strong>
                      </td>
                      <td className="py-2 px-4 border border-gray-300">
                        {value != null && value !== "" ? (
                          key === "logo" || key === "avatar" ? (
                            <Box maxW="100px">
                              <Image src={value} alt="Logo" />
                            </Box>
                          ) : key === "address" ? (
                            <>
                              {`${value.street}, ${value.city} (${
                                value.postalCode
                              }), ${
                                State.getStateByCodeAndCountry(
                                  value.state,
                                  "IN"
                                )?.name
                              }`}
                            </>
                          ) : key === "isPresent" ||
                            key === "isVerified" ||
                            key === "isFreeTrial" ? (
                            value ? (
                              "True"
                            ) : (
                              "False"
                            )
                          ) : key === "createdAt" ? (
                            moment(value).format("MMMM Do YYYY, h:mm:ss a")
                          ) : value == "undefined" ||
                            value == "null" ||
                            value == "" ? (
                            ""
                          ) : (
                            splitFieldName(value)
                          )
                        ) : null}
                      </td>
                    </tr>
                  )
              )}
            </tbody>
          </table>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ViewEmployeeModal;
