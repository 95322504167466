import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  token: null,
  isAuth: false,
  isAdmin: localStorage.getItem("isAdmin") === "true" ? true : false,
  navBarOpened: false,
  mobileView: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      state.token = null;
      state.isAuth = false;
      state.isAdmin = false;
      localStorage.clear();
    },
    login: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.isAuth = true;
      state.isAdmin = action.payload.isAdmin || false;

      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("user", JSON.stringify(action.payload.user));
      localStorage.setItem("isAuth", true);
      localStorage.setItem("isAdmin", action.payload.isAdmin || false);
    },

    signup: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.isAuth = true;
      state.isAdmin = action.payload.isAdmin || false;

      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("user", JSON.stringify(action.payload.user));
      localStorage.setItem("isAuth", true);
      localStorage.setItem("isAdmin", action.payload.isAdmin || false);
    },
    updateUser: (state, action) => {
      state.user = action.payload.user;
      state.token = localStorage.getItem("token");
      state.isAuth = true;
      state.isAdmin = action.payload.isAdmin || false;

      localStorage.setItem("user", JSON.stringify(action.payload.user));
      localStorage.setItem("isAuth", true);
      localStorage.setItem("isAdmin", action.payload.isAdmin || false);
      localStorage.setItem("status", "success");
    },
    deleteUser: (state, action) => {
      state.user = null;
      state.token = null;
      state.isAuth = false;
      state.isAdmin = false;
      localStorage.clear();
    },
    handleNavBar: (state, action) => {
      state.navBarOpened = !state.navBarOpened;
    },
    handleResolution: (state, action) => {
      state.mobileView = action.payload;
    },
  },
});

export const {
  logout,
  login,
  signup,
  updateUser,
  deleteUser,
  handleNavBar,
  handleResolution,
} = authSlice.actions;

export default authSlice.reducer;
