import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import ConfirmationModal from "../../../UI/ConfirmationModal";
import { getErrorMessage } from "../../../utils/functions";
// import DepartmentCard from "./DepartmentCard";
import {
  getAllRestaurants,
  deleteRestaurant,
} from "../../../services/restaurantApi";
import RestaurantTable from "./RestaurantTable";

const RestaurantList = ({ setTotalRestaurants, setShowRestaurant }) => {
  const [restaurants, setRestaurants] = useState([]);
  const [filteredRestaurants, setFilteredRestaurants] = useState([]);
  const [restaurant, setRestaurant] = useState({});
  const [open, setOpen] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    getAllRestaurants()
      .then((res) => {
        setRestaurants(res);
        setFilteredRestaurants(res);
        setTotalRestaurants(res.length);
      })
      .catch((err) => toast.error(err.message))
      .finally(setLoading(false));
  }, []);

  const showConfirmationModal = (restaurant) => {
    setOpen(true);
    setRestaurant(restaurant);
  };

  const handleDeleteRestaurant = async () => {
    try {
      setLoading(true);
      await deleteRestaurant(`${restaurant._id}`);
      setRestaurants((prevRestaurants) =>
        prevRestaurants.filter((emp) => emp._id !== restaurant._id)
      );
      setFilteredRestaurants((prevRestaurants) =>
        prevRestaurants.filter((emp) => emp._id !== restaurant._id)
      );
      setTotalRestaurants((prev) => prev - 1);
      toast.success("Restaurant deleted successfully!");
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setOpen(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    const filteredRestaurants = selectedDepartment
      ? restaurants.filter(
          (restaurant) => restaurant.department === selectedDepartment
        )
      : restaurants;
    setFilteredRestaurants(filteredRestaurants);
  }, [selectedDepartment, restaurants]);

  return (
    <div>
      <ConfirmationModal
        isOpen={open}
        message={"Are you sure you want to delete this restaurant?"}
        onClose={() => setOpen(false)}
        onConfirm={handleDeleteRestaurant}
        loading={loading}
      />
      <div className="p-3">
        <RestaurantTable
          restaurants={filteredRestaurants}
          showConfirmationModal={showConfirmationModal}
          setShowRestaurant={setShowRestaurant}
        />
      </div>
    </div>
  );
};

export default RestaurantList;
