import http from "./http";

const restaurantApi = "/restaurant/order";

export const createOrder = (data, query = "") => {
  return http.post(`${restaurantApi}/addToCart${query}`, data);
};

export const removeItemFromOrder = (data, query = "") => {
  return http.post(`${restaurantApi}/removeFromCart${query}`, data);
};

export const markItemAsDelivered = (data, query = "") => {
  return http.post(`${restaurantApi}/markItemAsDelivered${query}`, data);
};

export const markItemAsPrepared = (data, query = "") => {
  return http.post(`${restaurantApi}/markItemAsPrepared${query}`, data);
};

export const markAsPaid = (data, query = "") => {
  return http.post(`webhook/customer/cash/capture${query}`, data);
};

export const getAllOrders = async (query = "") => {
  const res = await http.get(`${restaurantApi}/all${query}`);
  return res;
};

export const getAllItemsByCondition = async (query = "") => {
  const res = await http.get(
    `${restaurantApi}/customer/category/items${query}`
  );
  return res;
};

export const getAllFoodCategories = async (query = "") => {
  const res = await http.get(`${restaurantApi}/categories${query}`);
  return res;
};
export const getChefSpecial = async (query = "") => {
  const res = await http.get(`${restaurantApi}/customer/special/${query}`);
  return res;
};

export const getTopRated = async (query = "") => {
  const res = await http.get(`${restaurantApi}/customer/top-rated/${query}`);
  return res;
};

export const deleteMenu = async (query) => {
  return http.delete(`${restaurantApi}/delete${query}`);
};

export const updateOrder = async (data, query) => {
  return http.put(`${restaurantApi}/update${query}`, data);
};
