import {
  Image,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { FaTrash } from "react-icons/fa";
import { TfiDownload } from "react-icons/tfi";
import ConfirmationModal from "../../../UI/ConfirmationModal";
import table_blue from "../../../assets/images/table_blue.png";
import table_green from "../../../assets/images/table_green.png";
import { createImageFileFromUrl } from "../../../utils/functions";

const TableCard = ({ table, handleDelete, loading }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showImage, setShowImage] = useState(false);

  const handleDownloadQR = async () => {
    try {
      const fileName = `Table_${table.index}_QRCode.png`;
      const file = await createImageFileFromUrl(table.qrCodeLink, fileName);

      // Create a temporary link element
      const link = document.createElement("a");
      link.href = URL.createObjectURL(file);
      link.download = fileName;

      // Append the link to the document and trigger a click
      document.body.appendChild(link);
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading QR code:", error);
    }
  };

  return (
    <>
      <ConfirmationModal
        isOpen={isOpen}
        loading={loading}
        message={"Are you sure you want to delete this table?"}
        onClose={() => setIsOpen(false)}
        onConfirm={handleDelete}
      />
      <div className="bg-[#ffe3e0] w-52 p-1 justify-center flex items-center flex-col relative rounded-md">
        <span className="text-black text-xl "> Table {table.index} </span>{" "}
        <div className=""></div>
        <div className="flex justify-evenly mt-2 w-full items-center">
          {/* <MdTableBar size={50} color="#ef6351" /> */}
          {table.isOccupied ? (
            <Image
              src={table_blue}
              className="rounded-lg aspect-square w-10 cursor-pointer"
            />
          ) : (
            <Image
              src={table_green}
              className="rounded-lg aspect-square w-10 cursor-pointer"
            />
          )}
          <Tooltip label="Click to enlarge" placement="top">
            <Popover trigger="hover" placement="right">
              <PopoverTrigger>
                <Image
                  src={table.qrCodeLink}
                  className="rounded-lg aspect-square w-10 cursor-pointer"
                  onClick={() => setShowImage(true)}
                />
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <Image
                  src={table.qrCodeLink}
                  className="rounded-lg"
                  maxW="200px"
                  maxH="200px"
                />
              </PopoverContent>
            </Popover>
          </Tooltip>
        </div>
        <div className="flex justify-evenly ">
          <button
            className="absolute top-1 right-7 p-1 bg-violet-500 text-white rounded-full font-bold"
            type="button"
            onClick={handleDownloadQR}
            title="Download"
          >
            <TfiDownload color="white" />
          </button>
          <button
            onClick={() => setIsOpen(true)}
            className="absolute top-1 right-0 p-1 bg-red-500 text-white rounded-full"
            title="Delete"
            type="button"
          >
            <FaTrash />
          </button>
        </div>
      </div>
    </>
  );
};

export default TableCard;
