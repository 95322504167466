import React from "react";
import { Skeleton, SkeletonText } from "@chakra-ui/react";
const Cards = () => {
  return (
    <div className="w-64 flex flex-col gap-2 mt-6 ">
      <Skeleton className=" h-36" />
      <div className="flex justify-between">
        <Skeleton className=" h-4 w-32" />
        <Skeleton className=" h-4 w-7" />
      </div>
    </div>
  );
};

export default Cards;
