import React, { useEffect, useState } from "react";
import { FaTrash } from "react-icons/fa";
import InputField from "../../../UI/InputField";
import {
  ROLES,
  employeeFields as employeeFormFields,
} from "../../../constants/constant";
import MyButton from "../../../UI/MyButton";
import { useSelector } from "react-redux";
const EmployeeForm = ({
  loading,
  employee,
  images,
  imagePreviews,
  handleImageUpload,
  removeImage,
  handleInputChange,
  handleSelectChange,
  addEmployee,
  isEmployeeEdit,
}) => {
  const settings = useSelector((state) => state.settings);
  const categories = settings.categories;

  const [employeeFields, setEmployeeFields] = useState([]);

  useEffect(() => {
    setEmployeeFields(employeeFormFields);
  }, [employee.department]);

  useEffect(() => {
    const updatedFields = [...employeeFormFields]; // Create a copy of the original array
    if (employee.department === "Chef") {
      updatedFields.push({
        label: "Kitchen Department",
        name: "category",
        type: "select",
      });
    }
    setEmployeeFields(updatedFields);
  }, [employee.department]);

  return (
    <div className="rounded-2xl p-3">
      <form
        className="flex flex-col lg:flex-row lg:space-x-8"
        onSubmit={addEmployee}
      >
        <div className="lg:w-1/2">
          <div className="flex gap-4 flex-col">
            {employeeFields?.slice(0, 3).map((field, index) => (
              <InputField
                isRequired={field.isRequired && !isEmployeeEdit}
                key={index}
                labelName={field.label}
                inputClass={
                  "h-10 bg-[#F6F1EE] rounded-lg  border border-black border-opacity-20"
                }
                type={field.type}
                name={field.name}
                value={employee[field.name]}
                options={field.options}
                labelClass={"text-black  mb-1 font-semibold"}
                onChange={
                  field.type === "select"
                    ? (selectedValue) =>
                        handleSelectChange(field.name, selectedValue)
                    : (e) => handleInputChange(field.name, e.target.value)
                }
              />
            ))}
          </div>
          <div className="mt-2 z-0">
            <div className="flex justify-center gap-4 p-3 -2xl flex-col z-0">
              <InputField
                id="profile_pic"
                hidden
                type="file"
                onChange={handleImageUpload}
                accept="image/png, image/jpeg"
                // multiple
              />
              <div className="flex justify-evenly flex-wrap">
                {imagePreviews.map((preview, index) => (
                  <div key={index} className="relative flex gap-3">
                    <img
                      key={index}
                      src={preview}
                      alt={`preview-${index}`}
                      className="aspect-square w-[200px] mt-2 lg:mt-0"
                    />
                    <MyButton
                      className="absolute top-0 right-0 p-1 bg-red-500 text-white rounded-full"
                      onClick={() => removeImage(index)}
                      type="button"
                      allowedRoles={[ROLES.SUPER_ADMIN, ROLES.RESTAURANT_ADMIN]}
                    >
                      <FaTrash />
                    </MyButton>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="lg:w-1/2">
          <div className="flex gap-4 flex-col">
            {employeeFields
              ?.slice(3, employeeFields.length)
              .map((field, index) => (
                <InputField
                  isRequired={field.isRequired && !isEmployeeEdit}
                  key={index}
                  labelName={field.label}
                  inputClass={
                    "h-10 bg-[#F6F1EE] rounded-lg  border border-black border-opacity-20"
                  }
                  type={field.type}
                  name={field.name}
                  value={employee[field.name]}
                  options={
                    field.name === "category"
                      ? categories.map((vari) => {
                          return {
                            label: vari.name,
                            value: vari.name,
                          };
                        })
                      : field.options
                  }
                  labelClass={"text-black mb-1 font-semibold"}
                  onChange={
                    field.type === "select"
                      ? (selectedValue) =>
                          handleSelectChange(field.name, selectedValue)
                      : (e) => handleInputChange(field.name, e.target.value)
                  }
                />
              ))}

            <MyButton
              className="bg-primary text-white rounded-xl p-2"
              type="submit"
              allowedRoles={[ROLES.SUPER_ADMIN, ROLES.RESTAURANT_ADMIN]}
            >
              {isEmployeeEdit ? "Update" : "Add"}
            </MyButton>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EmployeeForm;
