import React from "react";
import MyButton from "./MyButton";

const ConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  message,
  loading,
  children = null,
}) => {
  return (
    <>
      {isOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
          <div className="bg-[#F6F1EE] p-8 rounded-md shadow-md">
            <p className="text-lg mb-4">{message}</p>
            {children}
            <div className="flex justify-end mt-2">
              <MyButton
                className="mr-4 px-4 py-2 text-black bg-gray-400 rounded-md"
                onClick={onClose}
                bypassRoleCheck
              >
                Cancel
              </MyButton>
              <MyButton
                className="px-4 py-2 bg-red-500 text-white rounded-md"
                colorScheme="red"
                loading={loading}
                onClick={() => {
                  onConfirm();
                  onClose();
                }}
                bypassRoleCheck
              >
                Confirm
              </MyButton>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ConfirmationModal;
