import React from "react";
import imageConstant from "../../../constants/imageConstant";

const DepartmentCard = ({
  department,
  totalEmployeesInDepartment,
  isActive,
  ...props
}) => {
  if (!department || department === "undefined") return null;
  return (
    <div
      className={`mb-4 bg-gray-100 w-52 h-[4.3rem] flex flex-grow border-2 rounded-[2px] border-gray-50 p-2 gap-3 items-center ${
        isActive ? "border-yellow-200 bg-yellow-50" : ""
      }`}
      {...props}
    >
      <div>
        <img
          src={totalEmployeesInDepartment[0].avatar || imageConstant.waiter}
          alt={`${department} image`}
          className="w-12 aspect-square object-cover rounded-full"
        />
      </div>
      <div>
        <h2
          className={`font-semibold ${
            isActive ? "text-blue-800" : "text-slate-800"
          }`}
        >
          {department}
        </h2>
        <h2 className="font-semibold text-[.6rem]">
          {totalEmployeesInDepartment.length} employee
        </h2>
      </div>
    </div>
  );
};

export default DepartmentCard;
