import React, { useState } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { capitalizeFirstLetter } from "../../../utils/functions";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { useSelector } from "react-redux";

const FoodCard = ({ food, showConfirmationModal, showMenu, setShowMenu }) => {
  const [showAllDetails, setShowAllDetails] = useState(false);
  const navigate = useNavigate();

  const mobileView = useSelector((state) => state.auth.mobileView);

  const handleShowDetails = () => {
    setShowAllDetails((prev) => !prev);
  };

  const excludedKeys = [
    "thumbnail",
    "__v",
    "createdAt",
    "updatedAt",
    "_id",
    "name",
    "restaurant",
    "description",
    "price",
    "photo",
    "quantity",
    "quantityOptions",
    "isSpecial",
    "isActive",
    "photos",
    "isTopRated",
    "variant",
    "category"
  ];
  const handleEdit = () => {
    navigate(`/menus`, {
      state: { food, isEdit: true },
      replace: true,
    });
    setShowMenu(true);
  };

  return (
    <div
      className={`relative bg-transparent rounded-lg border-gray-500 border-opacity-10 border-[3px] p-1   m-1 lg:p-4 food-card lg:min-w-[240px] max-w-[15%] min-w-[160px] ${
        showAllDetails && "h-auto"
      } ${mobileView ? "mb-4" : ""}`}
      // style={{ minWidth: mobileView ? "100%" : "300px", maxHeight: "72rem" }}
    >
      <button
        className="absolute top-0 right-2 p-1 bg-red-500 text-white rounded-full"
        onClick={() => showConfirmationModal(food)}
        type="button"
      >
        <FaTrash />
      </button>
      <button
        className="absolute top-0 right-9 p-1 bg-blue-500 text-white rounded-full"
        onClick={() => handleEdit(food)}
        type="button"
      >
        <FaEdit />
      </button>
      <Swiper
        pagination={{
          dynamicBullets: true,
        }}
        modules={[Pagination]}
        className="mySwiper lg:mt-3 mt-5"
      >
        <SwiperSlide>
          <img
            src={food.thumbnail}
            alt="Food image"
            className="w-full lg:h-44 h-32 aspect-square object-cover mb-4 rounded-lg"
          />
        </SwiperSlide>
        {food.photos &&
          food.photos.length > 0 &&
          food.photos.map((photo, index) => (
            <SwiperSlide key={index}>
              <img
                src={photo}
                alt={`Food image ${index}`}
                className="w-full h-44 object-cover mb-4 rounded-lg"
              />
            </SwiperSlide>
          ))}
      </Swiper>
      <div className="flex justify-between items-center">
        <h2 className="lg:text-lg text-xs overflow-ellipsis font-semibold mb-2 ">
          {food?.name.length <= 15 ? food.name : `${food.name.slice(0,15)}...` }
        </h2>
        {/* <div className="flex justify-end items-center">
          <h4 className="mr-2 font-semibold lg:text-lg text-xs ">₹ {food.price}</h4>
        </div> */}
      </div>
      <div className="flex justify-between">
        <div className="text-gray-500">
          <p
            // onClick={handleShowDetails}
            className=" lg:text-lg text-xs"
          >
            {showAllDetails
              ? food.description
              : `${food.description.slice(0, 20)}...`}
          </p>
          {/* {showAllDetails && (
            <div className="grid gap-3 grid-cols-2 ">
              {Object.entries(food)
                .filter(([key]) => !excludedKeys.includes(key))
                .map(([key, value]) => (
                  <React.Fragment key={key}>
                    <span className="font-semibold text-wrap">
                      {capitalizeFirstLetter(key)}:
                    </span>
                    <span>{value}</span>
                  </React.Fragment>
                ))}
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default FoodCard;
